import React, { useCallback, useContext, useEffect, useState } from "react";
import {
  Pagination,
  PageHeader,
  Card,
  Button,
  Table,
  Dropdown,
  Menu,
  Modal,
} from "antd";
import { useTranslation } from "react-i18next";
import {
  AlignLeftOutlined,
  ApartmentOutlined,
  BellOutlined,
  CalendarOutlined,
  DownOutlined,
  HomeOutlined,
  UserOutlined,
} from "@ant-design/icons";

import {
  getAllActivities,
  updateActivityStatus,
} from "../../../network/network";
import FindActivityTypeIcon from "../../../components/FindActivityTypeIcon";
import moment from "moment";
import { AppContext } from "../../../context/AppContextProvider";
import { ACTIVITY_CREATED } from "../../../configs/constants";
import CommentsArea from "../CompanyGeneralDetails/CommentsArea";
import CustomBreadcrumb from "../../../components/CustomBreadcrumb";
import { useHistory, useLocation } from "react-router-dom";
import NotificationWithIcon from "../../../components/NotificationWithIcon";

const AllActivities = () => {
  const history = useHistory();
  const location = useLocation();
  const query = new URLSearchParams(location.search);
  const urlPage = parseInt(query.get("page")) || 1;

  const { setActiveMenu, authUser } = useContext(AppContext);
  const [activitiesData, setactivitiesData] = useState([]);
  const [reloadactivitiesData, setReloadactivitiesData] = useState(false);
  const [loading, setLoading] = useState(false);
  const [paginationData, setPaginationData] = useState([]);
  const [paginationPage, setPaginationPage] = useState(urlPage);
  const [activityId, setActivityId] = useState("");
  const [companyId, setCompanyId] = useState("");
  const [modalVisible, setModalVisible] = useState(false);
  const [modalData, setModalData] = useState("");
  const { t } = useTranslation();

  useEffect(() => {
    setLoading(true);
    getAllActivities(paginationPage)
      .then((response) => {
        setactivitiesData(response.data.data.activities);
        setPaginationData(response.data.data.pagination);
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      })
      .finally(() => {
        setLoading(false);
      });
  }, [reloadactivitiesData, paginationPage]);

  const handlePagination = useCallback(
    (page) => {
      setPaginationPage(page);
      const params = new URLSearchParams();
      params.append("page", page);
      history.push({
        pathname: "/activities",
        search: params.toString(),
      });
    },
    [history]
  );

  useEffect(() => {
    setPaginationPage(urlPage);
  }, [urlPage]);

  const reloadData = useCallback(() => {
    setReloadactivitiesData(!reloadactivitiesData);
  }, [reloadactivitiesData]);

  const completeActivityStatus = () => {
    const formData = new FormData();
    if (activityId) {
      formData.append("id", activityId);
      formData.append("status", 2);
    }
    updateActivityStatus(companyId, formData).then((response) => {
      let data = response.data || response.response.data;
      if (data.success === true) {
        setReloadactivitiesData(!reloadactivitiesData);
        // Success
        NotificationWithIcon(
          "success",
          t("activity_completed"),
          t("activity_completed_successfully")
        );
      } else {
        // Error
        NotificationWithIcon("error", t("something_went_wrong"), data?.message);
      }
    });
  };

  const menuComplete = (
    <Menu>
      <Menu.Item onClick={completeActivityStatus} key={0}>
        {t("complete")}
      </Menu.Item>
    </Menu>
  );

  const breadcrumbRoutes = [
    {
      path: "home",
      breadcrumbName: t("menu_home"),
      icon: <HomeOutlined />,
    },
    {
      path: "business_type_verifications",
      breadcrumbName: t("menu_activities"),
      icon: <ApartmentOutlined />,
    },
  ];

  const AllActivityTableColumns = [
    {
      title: "#",
      key: "index",
      width: "50px",
      render: (text, record, index) => {
        return paginationData.current_page
          ? paginationData.per_page * (paginationData.current_page - 1) +
              (index + 1)
          : index + 1;
      },
    },
    {
      title: t("title"),
      render: (activity) => {
        return (
          <>
            <span
              onClick={() => {
                setModalData(activity);
                setModalVisible(true);
              }}
              style={{ color: "#6301f2", cursor: "pointer" }}
            >
              {activity.title}
            </span>
          </>
        );
      },
    },
    {
      title: t("menu_activity_type"),
      align: "center",
      width: "170px",
      render: (activity) => {
        return (
          <>
            <span
              style={{
                backgroundColor:
                  activity.status === ACTIVITY_CREATED &&
                  moment().isAfter(moment(activity.end_date))
                    ? "#f5222d"
                    : "rgb(226, 232, 240)",
                color:
                  activity.status === ACTIVITY_CREATED &&
                  moment().isAfter(moment(activity.end_date))
                    ? "#ffffff"
                    : "#000000",
                padding: "3px 15px",
                borderRadius: "35rem",
              }}
            >
              <FindActivityTypeIcon
                name={activity.activity_type.icon}
                title={activity.activity_type.title}
              />
              <span style={{ marginLeft: "10px" }}>
                {activity.activity_type.title}
              </span>
            </span>
          </>
        );
      },
    },
    {
      title: t("business_name"),
      align: "center",
      width: "170px",
      render: (activity) => {
        return <>{activity.company.name ? activity.company.name : "-"}</>;
      },
    },
    {
      title: t("status"),
      align: "center",
      width: "150px",
      render: (activity) => {
        return activity.status === ACTIVITY_CREATED ? (
          authUser.id === activity.created_by.id ? (
            <Dropdown
              overlay={menuComplete}
              trigger={["click"]}
              onClick={() => {
                setCompanyId(activity.company.id);
                setActivityId(activity.id);
              }}
            >
              <Button size="small" danger>
                {t("incomplete")} <DownOutlined />
              </Button>
            </Dropdown>
          ) : (
            <Button size="small" danger>
              {t("incomplete")}
            </Button>
          )
        ) : (
          <Button
            size="small"
            style={{
              color: "#389e0d",
              background: "#f6ffed",
              borderColor: "#b7eb8f",
            }}
          >
            {t("complete")}
          </Button>
        );
      },
    },
    {
      title: t("assigned_to"),
      align: "center",
      width: "160px",
      render: (activity) => {
        return activity.assigned_to.name;
      },
    },
    {
      title: t("col_action"),
      align: "center",
      width: "140px",
      render: (activity) => {
        return (
          <>
            <Button
              type="primary"
              size="small"
              onClick={() => {
                setActiveMenu("companies");
                history.push("/companies/" + activity.company.id);
              }}
            >
              {t("view_company")}
            </Button>
          </>
        );
      },
    },
  ];

  return (
    <>
      <CustomBreadcrumb routes={breadcrumbRoutes} />
      <PageHeader
        className="site-page-header"
        title={t("menu_activities")}
        ghost={false}
        extra={[]}
      />
      <Card>
        <Table
          columns={AllActivityTableColumns}
          dataSource={activitiesData}
          loading={loading}
          rowKey="id"
          pagination={false}
        />
        {paginationData.current_page &&
          paginationData.count > 0 &&
          paginationData.total > paginationData.per_page && (
            <Pagination
              current={paginationPage}
              total={paginationData.total}
              pageSize={paginationData.per_page}
              onChange={handlePagination}
              showSizeChanger={false}
              style={{
                marginTop: "20px",
                textAlign: "center",
              }}
            />
          )}
      </Card>
      <Modal
        visible={modalVisible}
        title={t("activity_view")}
        onCancel={() => setModalVisible(false)}
        width={"1000px"}
        footer={null}
      >
        {modalData && (
          <div
            className="activity_card"
            style={{
              boxShadow: "rgba(154, 154, 154, 0.537) 0 0 20px -5px",
              marginBottom: "20px",
              borderRadius: "10px",
            }}
          >
            <div
              className="activity_head"
              style={{
                padding: "20px",
                borderBottom: "1px solid #e2e8f0",
              }}
            >
              <div style={{ display: "flex", alignItems: "center" }}>
                <div
                  style={{
                    display: "flex",
                    flexGrow: "1",
                    alignItems: "center",
                  }}
                >
                  <div
                    className="activity_title"
                    style={{
                      display: "flex",
                      flexGrow: "1",
                    }}
                  >
                    <h3 style={{ marginBottom: "0" }}>{modalData.title}</h3>
                  </div>

                  <div
                    className="activity_type"
                    style={{
                      backgroundColor: "rgb(226, 232, 240)",
                      padding: "7px 15px",
                      borderRadius: "35rem",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      columnGap: "10px",
                    }}
                  >
                    <FindActivityTypeIcon
                      name={modalData.activity_type.icon}
                      title={modalData.activity_type.title}
                    />
                    {modalData.activity_type.title}
                  </div>
                </div>
              </div>
            </div>
            <div className="activity_body" style={{ padding: "20px" }}>
              <div style={{ display: "flex", marginBottom: "10px" }}>
                <div style={{ marginRight: "10px", marginTop: "2px" }}>
                  <span>
                    <AlignLeftOutlined style={{ fontSize: "18px" }} />
                  </span>
                </div>
                <div
                  style={{
                    textAlign: "justify",
                  }}
                >
                  {modalData.description}
                </div>
              </div>
              <div style={{ display: "flex", marginBottom: "10px" }}>
                <div style={{ marginRight: "10px", marginTop: "2px" }}>
                  <span>
                    <UserOutlined style={{ fontSize: "18px" }} />
                  </span>
                </div>
                <div
                  style={{
                    display: "flex",
                    flexGrow: "1",
                  }}
                >
                  <div>{modalData.assigned_to.name}</div>
                </div>
              </div>
              <div style={{ display: "flex", marginBottom: "10px" }}>
                <div style={{ marginRight: "10px", marginTop: "2px" }}>
                  <span>
                    <CalendarOutlined style={{ fontSize: "18px" }} />
                  </span>
                </div>
                <div
                  style={{
                    display: "flex",
                    flexGrow: "1",
                  }}
                >
                  <div>
                    {moment(modalData.start_date).format("MMM. D, YYYY HH:mm") +
                      " to " +
                      moment(modalData.end_date).format("MMM. D, YYYY HH:mm")}
                  </div>
                </div>
              </div>
              {modalData.reminder && (
                <div style={{ display: "flex" }}>
                  <div style={{ marginRight: "10px", marginTop: "2px" }}>
                    <span>
                      <BellOutlined style={{ fontSize: "18px" }} />
                    </span>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      flexGrow: "1",
                    }}
                  >
                    <div>
                      {moment(modalData.reminder).format("MMM. D, YYYY HH:mm")}
                    </div>
                  </div>
                </div>
              )}
            </div>
            <div
              className="activity_footer"
              style={{
                padding: "20px",
                backgroundColor: "#f8fafc",
                borderRadius: "0 0 10px 10px",
              }}
            >
              <CommentsArea
                data={modalData}
                type="activity"
                reloadData={reloadData}
              />
            </div>
          </div>
        )}
      </Modal>
    </>
  );
};

export default AllActivities;
