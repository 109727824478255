import React, { useCallback, useContext, useEffect, useState } from "react";
import {
  Button,
  Form,
  Input,
  Popover,
  Tabs,
  Tooltip,
  Modal,
  DatePicker,
  Select,
  Popconfirm,
  Pagination,
  Radio,
  Empty,
  Spin,
} from "antd";
import { useTranslation } from "react-i18next";
import {
  AlignLeftOutlined,
  BellOutlined,
  CalendarOutlined,
  CheckCircleFilled,
  CheckCircleTwoTone,
  DeleteTwoTone,
  EditFilled,
  MoreOutlined,
  PlusOutlined,
  UserOutlined,
} from "@ant-design/icons";
import NotificationWithIcon from "../../../components/NotificationWithIcon";
import { useParams } from "react-router-dom";
import {
  createActivity,
  deleteActivity,
  getActivities,
  getActivityTypes,
  getAllUsers,
  updateActivity,
  updateActivityStatus,
} from "../../../network/network";
import FindActivityTypeIcon from "../../../components/FindActivityTypeIcon";
import moment from "moment";
import CommentsArea from "./CommentsArea";
import { AppContext } from "../../../context/AppContextProvider";
import { ACTIVITY_CREATED, ACTIVITY_DONE } from "../../../configs/constants";

const CompanyActivities = () => {
  const { authUser } = useContext(AppContext);
  const ADD_ACTIVITY = "Add Activity";
  const [modalVisible, setModalVisible] = useState(false);
  const [activityForm] = Form.useForm();
  const [activitiesData, setactivitiesData] = useState([]);
  const [activityTypes, setActivityTypes] = useState([]);
  const [allUsers, setAllUsers] = useState([]);
  const [reloadactivitiesData, setReloadactivitiesData] = useState(false);
  const [loading, setLoading] = useState(false);
  const [modalTitle, setModalTitle] = useState("");
  const [activityType, setActivityType] = useState("");
  const [paginationData, setPaginationData] = useState([]);
  const [paginationPage, setPaginationPage] = useState(1);
  const { t } = useTranslation();
  const { RangePicker } = DatePicker;
  let { companyId } = useParams();

  useEffect(() => {
    setLoading(true);
    getActivities(companyId, paginationPage)
      .then((response) => {
        setactivitiesData(response.data.data.activities);
        setPaginationData(response.data.data.pagination);
      })
      .finally(() => {
        setLoading(false);
      });
  }, [reloadactivitiesData, companyId, paginationPage]);

  useEffect(() => {
    setLoading(true);
    getActivityTypes()
      .then((response) => {
        setActivityTypes(response.data.data.activity_types);
      })
      .finally(() => {
        setLoading(false);
      });
    getAllUsers()
      .then((response) => {
        setAllUsers(response.data.data.crew);
      })
      .finally(() => {
        setLoading(false);
      });
  }, []);

  const handlePagination = (page, pageSize) => {
    setPaginationPage(page);
  };

  const content = (activityId) => (
    <>
      <Button
        style={{
          border: 0,
          boxShadow: 0,
          display: "block",
          marginBottom: "5px",
        }}
        icon={<EditFilled title={t("edit")} />}
        onClick={() => handleEditModal(activityId)}
      >
        {t("edit")}
      </Button>
      <Popconfirm
        title={t("warning_delete")}
        onConfirm={() => handleActivityDelete(activityId)}
        okText={t("yes")}
        cancelText={t("no")}
      >
        <Button
          style={{ border: 0, boxShadow: 0, display: "block" }}
          icon={
            <DeleteTwoTone twoToneColor="#ff0000" title={t("delete_entity")} />
          }
        >
          {t("delete")}
        </Button>
      </Popconfirm>
    </>
  );

  const showModal = () => {
    // Show activity Modal
    setModalVisible(true);
    // Set add activity Modal Title
    setModalTitle(t("activity_add"));
    // Reset activity Form Fields
    activityForm.resetFields();
  };

  const handleAPIResponse = (response, type) => {
    if (response.data.success === true) {
      setReloadactivitiesData(!reloadactivitiesData);
      // Success
      if (type === ADD_ACTIVITY) {
        NotificationWithIcon(
          "success",
          t("activity_added"),
          response.data.message
          // t("activity_added_successfully")
        );
      } else {
        NotificationWithIcon(
          "success",
          t("activity_updated"),
          response.data.message
          // t("activity_updated_successfully")
        );
      }
    } else {
      // Error
      NotificationWithIcon(
        "error",
        t("something_went_wrong"),
        response.data.message
      );
    }
    // Reset Form Fields
    activityForm.resetFields();
    // Hide Modal
    setModalVisible(false);
  };

  const handleAddEdit = () => {
    activityForm
      .validateFields()
      .then((values) => {
        // Set Loading True
        setLoading(true);
        if (activityType) {
          activityForm.setFieldsValue({
            type: activityType,
          });
        }

        // set start_end_date value to seprate start and end date
        values.start_date = values.start_end_date[0];
        values.end_date = values.start_end_date[1];
        // Sending Request to API
        if (modalTitle === ADD_ACTIVITY || modalTitle === t("activity_add")) {
          createActivity(companyId, values)
            .then((response) => {
              let data = response.data || response.response.data;
              if (data.success === true) {
                return handleAPIResponse(response, ADD_ACTIVITY);
              } else {
                let errors = data?.data?.errors;
                if (errors && Object.keys(errors).length) {
                  // Error
                  NotificationWithIcon(
                    "error",
                    t("something_went_wrong"),
                    errors[Object.keys(errors)[0]]
                  );
                }
              }
            })
            .catch((info) => {
              console.log("API Failed:", info.response);
              // Error
              NotificationWithIcon(
                "error",
                t("something_went_wrong"),
                info.response.data.data.errors.name
              );
            })
            .finally(() => {
              // Set Loading False
              setLoading(false);
            });
        } else {
          updateActivity(companyId, values)
            .then((response) => {
              let data = response.data || response.response.data;
              if (data.success === true) {
                return handleAPIResponse(response, null);
              } else {
                let errors = data?.data?.errors;
                if (errors && Object.keys(errors).length) {
                  // Error
                  NotificationWithIcon(
                    "error",
                    t("something_went_wrong"),
                    errors[Object.keys(errors)[0]]
                  );
                }
              }
            })
            .catch((info) => {
              console.log("API Failed:", info.response);
              // Error
              NotificationWithIcon(
                "error",
                t("something_went_wrong"),
                info.response.data.message
              );
            })
            .finally(() => {
              // Set Loading False
              setLoading(false);
            });
        }
      })
      .catch((info) => {
        console.log("Validate Failed:", info);
      });
  };

  const handleEditModal = (id) => {
    // Find activity from DataArray
    const activity = activitiesData.find((activity) => activity.id === id);
    // Set Modal Title
    setModalTitle(t("activity_edit") + " : " + activity.title);
    // Show Modal
    setModalVisible(true);
    // Set Form Fields
    setActivityType(activity.activity_type.id);
    activityForm.setFieldsValue({
      id: activity.id,
      title: activity.title,
      activity_type_id: activity.activity_type.id,
      start_end_date: [moment(activity.start_date), moment(activity.end_date)],
      reminder: activity.reminder && moment(activity.reminder),
      assigned_to: activity.assigned_to.id,
      description: activity.description,
    });
  };

  const handleCancel = () => {
    // Reset Form Fields
    activityForm.resetFields();
    // close modal
    setModalVisible(false);
    // reset activity type
    setActivityType(false);
  };

  const handleActivityDelete = (id) => {
    deleteActivity(companyId, id).then((response) => {
      let data = response.data || response.response.data;
      if (data.success === true) {
        setReloadactivitiesData(!reloadactivitiesData);
        // Success
        NotificationWithIcon(
          "success",
          t("activity_deleted"),
          data.message
          // t("activity_deleted_successfully")
        );
      } else {
        // Error
        NotificationWithIcon("error", t("something_went_wrong"), data?.message);
      }
    });
  };

  const handleActivityStatus = (id) => {
    const formData = new FormData();
    if (id) {
      formData.append("id", id);
      formData.append("status", 2);
    }
    updateActivityStatus(companyId, formData).then((response) => {
      let data = response.data || response.response.data;
      if (data.success === true) {
        setReloadactivitiesData(!reloadactivitiesData);
        // Success
        NotificationWithIcon(
          "success",
          t("activity_completed"),
          t("activity_completed_successfully")
        );
      } else {
        // Error
        NotificationWithIcon("error", t("something_went_wrong"), data?.message);
      }
    });
  };

  const disabledDate = (current) => {
    return current && current.valueOf() < Date.now();
  };

  const reloadData = useCallback(() => {
    setReloadactivitiesData(!reloadactivitiesData);
  }, [reloadactivitiesData]);

  return (
    <>
      <Spin size="large" spinning={loading}>
        <div style={{ marginBottom: "20px", textAlign: "right" }}>
          <Button type="primary" onClick={showModal}>
            <PlusOutlined title={t("activity_add")} /> {t("activity_add")}
          </Button>
        </div>
        <Tabs defaultActiveKey="tab-all" centered>
          <Tabs.TabPane
            tab={"All (" + activitiesData.length + ")"}
            key="tab-all"
          >
            {activitiesData.length > 0 ? (
              <>
                {activitiesData.map((activity) => (
                  <div
                    className="activity_card"
                    style={{
                      boxShadow: "rgba(154, 154, 154, 0.537) 0 0 20px -5px",
                      margin: "20px",
                      borderRadius: "10px",
                    }}
                    key={activity.id}
                  >
                    <div
                      className="activity_head"
                      style={{
                        padding: "20px",
                        borderBottom: "1px solid #e2e8f0",
                      }}
                    >
                      <div style={{ display: "flex", alignItems: "center" }}>
                        <div style={{ marginRight: "10px" }}>
                          {activity.status === ACTIVITY_DONE ? (
                            <Tooltip title={"Activity Completed"}>
                              <CheckCircleFilled
                                style={{ color: "#52c41a", fontSize: "18px" }}
                              />
                            </Tooltip>
                          ) : (
                            <Tooltip
                              title={
                                authUser.id === activity.created_by.id
                                  ? "Mark as Completed"
                                  : "You can't complete others activity"
                              }
                            >
                              <CheckCircleTwoTone
                                twoToneColor="#52c41a"
                                style={{ fontSize: "18px", cursor: "pointer" }}
                                onClick={() =>
                                  authUser.id === activity.created_by.id &&
                                  handleActivityStatus(activity.id)
                                }
                              />
                            </Tooltip>
                          )}
                        </div>
                        <div
                          style={{
                            display: "flex",
                            flexGrow: "1",
                            alignItems: "center",
                          }}
                        >
                          <div
                            className="activity_title"
                            style={{
                              display: "flex",
                              flexGrow: "1",
                            }}
                          >
                            <h3 style={{ marginBottom: "0" }}>
                              {activity.title}
                            </h3>
                          </div>
                          <div
                            className="activity_type"
                            style={{
                              backgroundColor: "rgb(226, 232, 240)",
                              padding: "7px 15px",
                              borderRadius: "35rem",
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "center",
                              columnGap: "10px",
                            }}
                          >
                            <FindActivityTypeIcon
                              name={activity.activity_type.icon}
                              title={activity.activity_type.title}
                            />
                            {activity.activity_type.title}
                          </div>
                        </div>
                        {activity.status === ACTIVITY_CREATED &&
                          authUser.id === activity.created_by.id && (
                            <div style={{ marginLeft: "10px" }}>
                              <Popover
                                placement="left"
                                content={content(activity.id)}
                                trigger="hover"
                              >
                                <MoreOutlined
                                  style={{
                                    fontSize: "18px",
                                    cursor: "pointer",
                                  }}
                                />
                              </Popover>
                            </div>
                          )}
                      </div>
                    </div>
                    <div className="activity_body" style={{ padding: "20px" }}>
                      <div style={{ display: "flex", marginBottom: "10px" }}>
                        <div style={{ marginRight: "10px", marginTop: "2px" }}>
                          <span>
                            <AlignLeftOutlined style={{ fontSize: "18px" }} />
                          </span>
                        </div>
                        <div
                          style={{
                            textAlign: "justify",
                          }}
                        >
                          {activity.description}
                        </div>
                      </div>
                      <div style={{ display: "flex", marginBottom: "10px" }}>
                        <div style={{ marginRight: "10px", marginTop: "2px" }}>
                          <span>
                            <UserOutlined style={{ fontSize: "18px" }} />
                          </span>
                        </div>
                        <div
                          style={{
                            display: "flex",
                            flexGrow: "1",
                          }}
                        >
                          <div
                            style={{
                              display: "flex",
                              flexGrow: "1",
                            }}
                          >
                            <div>{activity.assigned_to.name}</div>
                          </div>
                        </div>
                      </div>
                      <div style={{ display: "flex", marginBottom: "10px" }}>
                        <div style={{ marginRight: "10px", marginTop: "2px" }}>
                          <span>
                            <CalendarOutlined style={{ fontSize: "18px" }} />
                          </span>
                        </div>
                        <div
                          style={{
                            display: "flex",
                            flexGrow: "1",
                          }}
                        >
                          <div>
                            {" "}
                            {moment(activity.start_date).format(
                              "MMM. D, YYYY HH:mm"
                            ) +
                              " to " +
                              moment(activity.end_date).format(
                                "MMM. D, YYYY HH:mm"
                              )}
                          </div>
                        </div>
                      </div>
                      {activity.reminder && (
                        <div style={{ display: "flex" }}>
                          <div
                            style={{ marginRight: "10px", marginTop: "2px" }}
                          >
                            <span>
                              <BellOutlined style={{ fontSize: "18px" }} />
                            </span>
                          </div>
                          <div
                            style={{
                              display: "flex",
                              flexGrow: "1",
                            }}
                          >
                            <div>
                              {moment(activity.reminder).format(
                                "MMM. D, YYYY HH:mm"
                              )}
                            </div>
                          </div>
                        </div>
                      )}
                    </div>
                    <div
                      className="activity_footer"
                      style={{
                        padding: "20px",
                        backgroundColor: "#f8fafc",
                        borderRadius: "0 0 10px 10px",
                      }}
                    >
                      <CommentsArea
                        data={activity}
                        type="activity"
                        reloadData={reloadData}
                      />
                    </div>
                  </div>
                ))}
                {paginationData.current_page &&
                  paginationData.count > 0 &&
                  paginationData.total > paginationData.per_page && (
                    <Pagination
                      current={paginationData.current_page}
                      total={paginationData.total}
                      pageSize={paginationData.per_page}
                      onChange={handlePagination}
                      showSizeChanger={false}
                      defaultCurrent={paginationData.current_page}
                      style={{
                        marginTop: "20px",
                        textAlign: "center",
                      }}
                    />
                  )}
              </>
            ) : (
              <Empty />
            )}
          </Tabs.TabPane>
          {/* <Tabs.TabPane tab="Today" key="tab-today ">
            Today
          </Tabs.TabPane> */}
          {/* <Tabs.TabPane tab="Tomorrow" key="tab-tomorrow">
            Tomorrow
          </Tabs.TabPane> */}
          {/* <Tabs.TabPane tab="This Week" key="tab-this-week">
            This Week
          </Tabs.TabPane> */}
          {/* <Tabs.TabPane tab="Next Week" key="tab-next-week ">
            Next Week
          </Tabs.TabPane> */}
          {/* <Tabs.TabPane tab="Done" key="tab-done">
            Done
          </Tabs.TabPane> */}
        </Tabs>
        {/* add or edit activity */}
        <Modal
          visible={modalVisible}
          title={modalTitle}
          onOk={handleAddEdit}
          onCancel={handleCancel}
          style={{ top: 10 }}
          footer={[
            <Button key="back" loading={loading} onClick={handleCancel}>
              {t("cancel")}
            </Button>,
            <Button
              key="submit"
              type="primary"
              loading={loading}
              onClick={handleAddEdit}
            >
              {modalTitle === ADD_ACTIVITY || modalTitle === t("activity_add")
                ? t("activity_add")
                : t("update")}
            </Button>,
          ]}
        >
          <Form
            name="activity-form"
            layout="vertical"
            onFinish={handleAddEdit}
            style={{ width: "100%", margin: "0" }}
            form={activityForm}
          >
            <Form.Item
              name="title"
              label={t("title")}
              rules={[
                {
                  required: true,
                  message: t("validate_title_required"),
                  whitespace: true,
                },
              ]}
              hasFeedback
            >
              <Input />
            </Form.Item>

            <Form.Item
              name="activity_type_id"
              label={t("type")}
              rules={[
                {
                  required: true,
                  message: t("validate_activity_type_required"),
                },
              ]}
            >
              <Radio.Group
                options={activityTypes.map((type) => {
                  return {
                    label: (
                      <FindActivityTypeIcon
                        name={type.icon}
                        title={type.title}
                      />
                    ),
                    // label: type.icon,
                    value: type.id,
                  };
                })}
                onChange={(e) => {
                  setActivityType(e.target.value);
                }}
                optionType="button"
                buttonStyle="solid"
              ></Radio.Group>
            </Form.Item>
            <Form.Item
              name="start_end_date"
              label={t("date")}
              rules={[{ required: true, message: t("validate_date_required") }]}
            >
              <RangePicker
                disabledDate={disabledDate}
                style={{ width: "100%" }}
                showTime={{
                  format: "HH:mm",
                }}
                format="DD-MM-YYYY HH:mm"
                allowClear
              />
            </Form.Item>
            <Form.Item name="reminder" label={t("reminder")}>
              <DatePicker
                disabledDate={disabledDate}
                style={{ width: "100%" }}
                showTime={{
                  format: "HH:mm",
                }}
                format="DD-MM-YYYY HH:mm"
                allowClear
              />
            </Form.Item>
            <Form.Item
              name="assigned_to"
              style={{ width: "100%" }}
              label={t("assigned_to")}
              rules={[
                {
                  required: true,
                  message: t("validate_assigned_to_required"),
                },
              ]}
            >
              <Select
                showSearch
                allowClear
                options={allUsers.map((user) => {
                  return {
                    label: user.name,
                    value: user.id,
                  };
                })}
                filterOption={(input, option) =>
                  (option?.label ?? "")
                    .toLowerCase()
                    .includes(input.toLowerCase())
                }
              />
            </Form.Item>
            <Form.Item
              name="description"
              label={t("description")}
              rules={[
                { required: true, message: t("validate_description_required") },
              ]}
            >
              <Input.TextArea rows={3} />
            </Form.Item>
            <Form.Item name="id" style={{ display: "none" }}>
              <input type="hidden" />
            </Form.Item>
          </Form>
        </Modal>
      </Spin>
    </>
  );
};

export default CompanyActivities;
