import { Link } from "react-router-dom";
import { Breadcrumb } from "antd";

const CustomBreadcrumb = ({ routes }) => {
  function itemRender(route, params, routes, paths) {
    const last = routes.indexOf(route) === routes.length - 1;
    return last ? (
      <span>
        {route.icon ?? false}
        {route.icon && " "}
        {route.breadcrumbName}
      </span>
    ) : (
      <Link className="ant-breadcrumb-link" to={route.path}>
        {route.icon ?? false}
        {route.icon && " "}
        {route.breadcrumbName}
      </Link>
    );
  }

  return (
    <div
      className="ant-page-header"
      style={{ marginBottom: 0, paddingBottom: 0 }}
    >
      <Breadcrumb itemRender={itemRender} routes={routes} />
    </div>
  );
};

export default CustomBreadcrumb;
