import React, { useContext, useState } from "react";
import { Button, Form, Space, Popconfirm, Spin } from "antd";
import { useTranslation } from "react-i18next";
import {
  CommentOutlined,
  DeleteOutlined,
  DownOutlined,
  PlusOutlined,
  RightOutlined,
  UserOutlined,
} from "@ant-design/icons";
import TextArea from "antd/lib/input/TextArea";
import NotificationWithIcon from "../../../components/NotificationWithIcon";
import {
  createComment,
  deleteComment,
  getActivityComments,
  getNoteComments,
} from "../../../network/network";
import moment from "moment";
import { AppContext } from "../../../context/AppContextProvider";
import { USER_TYPE_SUPER_ADMIN } from "../../../configs/constants";

const CommentsArea = ({ data, type, reloadData }) => {
  const { authUser } = useContext(AppContext);
  const [showCommentBox, setShowCommentBox] = useState(false);
  const [showCommentList, setShowCommentList] = useState(false);
  const [comments, setComments] = useState([]);
  const [commentForm] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const { t } = useTranslation();
  let dataId = data.id;

  const handleCommentAdd = () => {
    commentForm
      .validateFields()
      .then((values) => {
        // Set Loading True
        setLoading(true);
        // Sending Request to API
        createComment(values)
          .then((response) => {
            if (response.data.success === true) {
              setLoading(true);
              reloadData(true);
              // Success
              NotificationWithIcon(
                "success",
                t("comment_added"),
                response.data.message
                // t("comment_added_successfully")
              );
            } else {
              // Error
              NotificationWithIcon(
                "error",
                t("something_went_wrong"),
                response.data.message
              );
            }
            // Reset Form Fields
            commentForm.resetFields();
            // Hide box
            setShowCommentBox();
          })
          .catch((info) => {
            console.log("API Failed:", info.response);
            // Error
            NotificationWithIcon(
              "error",
              t("something_went_wrong"),
              info.response.data.data.errors.name
            );
          })
          .finally(() => {
            // close comment box
            setShowCommentBox();
            // Set Loading False
            setLoading(false);
          });
      })
      .catch((info) => {
        console.log("Validate Failed:", info);
      });
  };

  const handleCommentDelete = (id) => {
    deleteComment(id).then((response) => {
      let data = response.data || response.response.data;
      if (data.success === true) {
        reloadData(true);
        // Success
        NotificationWithIcon(
          "success",
          t("comment_deleted"),
          // t("comment_deleted_successfully")
          response.data.message
        );
      } else {
        // Error
        NotificationWithIcon("error", t("something_went_wrong"), data?.message);
      }
      // Hide box
      setShowCommentList();
    });
  };

  const ShowHideCommentBox = () => {
    commentForm.setFieldsValue({
      note_id: dataId,
      activity_id: dataId,
    });
    setShowCommentBox(dataId);
    setShowCommentList();
  };

  const ShowHideComments = () => {
    setLoading(true);
    showCommentList ? (
      <>{(setShowCommentList(), setLoading(false))} </>
    ) : (
      <>
        {
          (setShowCommentList(dataId),
          type === "note"
            ? getNoteComments(dataId).then((response) => {
                setComments(response.data.data.comments);
                setLoading(false);
              })
            : getActivityComments(dataId).then((response) => {
                setComments(response.data.data.comments);
                setLoading(false);
              }))
        }
      </>
    );
  };

  return (
    <>
      {showCommentBox !== dataId && (
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <div>
            {data.comments_count > 0 && (
              <Button
                type="link"
                style={{ padding: "0", margin: "0", height: "auto" }}
                onClick={ShowHideComments}
              >
                <CommentOutlined title={t("comment")} /> {t("comment")} (
                {data.comments_count})
                {showCommentList === dataId ? (
                  <DownOutlined />
                ) : (
                  <RightOutlined />
                )}
              </Button>
            )}
          </div>
          <Button
            type="link"
            style={{ padding: "0", margin: "0", height: "auto" }}
            onClick={ShowHideCommentBox}
          >
            <PlusOutlined title={t("comment_add")} /> {t("comment_add")}
          </Button>
        </div>
      )}
      {showCommentBox === dataId && (
        <Form
          name="manage-comment-form"
          layout="vertical"
          onFinish={handleCommentAdd}
          style={{ width: "100%", margin: "0" }}
          form={commentForm}
        >
          <Form.Item
            name="description"
            label={t("comment")}
            rules={[
              {
                required: true,
              },
            ]}
          >
            <TextArea rows={4} placeholder="Write a new comment here." />
          </Form.Item>
          {type === "note" ? (
            <Form.Item name="note_id" style={{ display: "none" }}>
              <input type="hidden" />
            </Form.Item>
          ) : (
            <Form.Item name="activity_id" style={{ display: "none" }}>
              <input type="hidden" />
            </Form.Item>
          )}
          <Space>
            <Button
              key="back"
              onClick={() => {
                setShowCommentBox();
              }}
            >
              {t("cancel")}
            </Button>
            <Button htmlType="submit" type="primary" loading={loading}>
              {t("save")}
            </Button>
          </Space>
        </Form>
      )}

      {showCommentList === dataId && (
        <Spin spinning={loading}>
          {comments.map((comment) => (
            <div
              className="comment_area"
              style={{
                boxShadow: "rgba(154, 154, 154, 0.537) 0 0 20px -5px",
                padding: "20px",
                backgroundColor: "#fff",
                marginTop: "20px",
              }}
              key={comment.id}
            >
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  marginBottom: "10px",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    flexGrow: "1",
                    alignItems: "center",
                  }}
                >
                  <div style={{ marginRight: "10px" }}>
                    <UserOutlined style={{ fontSize: "18px" }} />
                  </div>
                  <div
                    style={{
                      display: "flex",
                      flexGrow: "1",
                    }}
                  >
                    <span
                      style={{
                        fontWeight: "bold",
                        marginRight: "5px",
                      }}
                    >
                      {comment.created_by.name}
                    </span>
                    add a comment on
                    <span
                      style={{
                        fontWeight: "bold",
                        marginLeft: "5px",
                      }}
                    >
                      {moment(comment.created_at).format("MMM. D, YYYY HH:mm")}
                    </span>
                  </div>
                </div>
                {authUser.type === USER_TYPE_SUPER_ADMIN && (
                  <div style={{ marginLeft: "10px" }}>
                    <Popconfirm
                      title={t("warning_delete")}
                      onConfirm={() => handleCommentDelete(comment.id)}
                      okText={t("yes")}
                      cancelText={t("no")}
                    >
                      <Button
                        type="button"
                        style={{
                          padding: "2px 10px",
                          backgroundColor: "#ff0000",
                          color: "#ffffff",
                        }}
                      >
                        <DeleteOutlined />
                      </Button>
                    </Popconfirm>
                  </div>
                )}
              </div>
              <div style={{ textAlign: "justify" }}>{comment.description}</div>
            </div>
          ))}
        </Spin>
      )}
    </>
  );
};

export default CommentsArea;
