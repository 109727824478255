import React, { useEffect, useState } from "react";
import { PageHeader, Card, Form, Input, Button, Table, Modal } from "antd";
import { HomeOutlined, ApartmentOutlined } from "@ant-design/icons";
import { getBusinessTypes, createBusinessType } from "../../../network/network";
import CustomBreadcrumb from "../../../components/CustomBreadcrumb";
import NotificationWithIcon from "../../../components/NotificationWithIcon";
import { useTranslation } from "react-i18next";

const BusinessTypeVerifications = () => {
  const ADD_BUSINESS_TYPE = "Add Business Type";
  const [modalTitle, setModalTitle] = useState(ADD_BUSINESS_TYPE);
  const [modalVisible, setModalVisible] = useState(false);
  const [loading, setLoading] = useState(false);
  const [isDataLoading, setIsDataLoading] = useState(false);
  const [reloadTableData, setReloadTableData] = useState(false);
  const [businessTypesTableData, setBusinessTypesTableData] = useState([]);
  const [businessTypeForm] = Form.useForm();
  const { t } = useTranslation();

  useEffect(() => {
    setIsDataLoading(true);
    getBusinessTypes()
      .then((response) => {
        setBusinessTypesTableData(
          response.data.data.business_type_verifications
        );
      })
      .finally(() => {
        setIsDataLoading(false);
      });
  }, [reloadTableData]);

  const showAddModal = () => {
    // Set Modal Title
    setModalTitle(t("business_type_add"));

    // Reset Form Fields
    businessTypeForm.resetFields();

    // Show Modal
    setModalVisible(true);
  };

  const handleAPIResponse = (data, type) => {
    if (data.success === true) {
      setReloadTableData(!reloadTableData);
      // Success
      if (type === ADD_BUSINESS_TYPE) {
        NotificationWithIcon("success", t("business_type_added"), data.message);
      } else {
        NotificationWithIcon(
          "success",
          t("business_type_updated"),
          t("business_type_updated_successfully")
        );
      }
    } else {
      // Error
      NotificationWithIcon("error", t("something_went_wrong"), data.message);
    }
    // Reset Form Fields
    businessTypeForm.resetFields();
    // Hide Modal
    setModalVisible(false);
  };

  const handleAddEditIssueType = () => {
    businessTypeForm
      .validateFields()

      .then((values) => {
        // Set Loading True
        setLoading(true);

        // Sending Request to API

        createBusinessType(values)
          .then((response) => {
            let data = response.data || response.response.data;
            if (data.success === true) {
              return handleAPIResponse(data, ADD_BUSINESS_TYPE);
            } else {
              let errors = data?.data?.errors;
              if (errors && Object.keys(errors).length) {
                // Error
                NotificationWithIcon(
                  "error",
                  t("something_went_wrong"),
                  errors[Object.keys(errors)[0]]
                );
              }
            }
          })
          .finally(() => {
            // Set Loading False
            setLoading(false);
          });
      })
      .catch((info) => {
        console.log("Validate Failed:", info);
      });
  };

  const handleCancel = () => {
    // Reset Form Fields
    businessTypeForm.resetFields();
    setModalVisible(false);
  };

  const breadcrumbRoutes = [
    {
      path: "home",
      breadcrumbName: t("menu_home"),
      icon: <HomeOutlined />,
    },
    {
      path: "business_type_verifications",
      breadcrumbName: t("menu_business_type_verifications"),
      icon: <ApartmentOutlined />,
    },
  ];

  const IssueTypesTableColumns = [
    {
      title: "#",
      key: "index",
      width: "100px",
      render: (text, record, index) => {
        return <>{index + 1}</>;
      },
    },
    {
      title: t("type"),
      dataIndex: "type",
    },
  ];

  return (
    <>
      <CustomBreadcrumb routes={breadcrumbRoutes} />
      <PageHeader
        className="site-page-header"
        title={t("menu_business_type_verifications")}
        ghost={false}
        extra={[
          <Button
            key="open-add-business-type-modal"
            type="primary"
            onClick={showAddModal}
          >
            + {t("business_type_add")}
          </Button>,
        ]}
      />
      <Card>
        <Table
          columns={IssueTypesTableColumns}
          dataSource={businessTypesTableData}
          loading={isDataLoading}
          rowKey="id"
          pagination={false}
        />
      </Card>
      <Modal
        visible={modalVisible}
        title={modalTitle}
        onOk={handleAddEditIssueType}
        onCancel={handleCancel}
        footer={[
          <Button key="back" loading={loading} onClick={handleCancel}>
            {t("cancel")}
          </Button>,
          <Button
            key="submit"
            type="primary"
            loading={loading}
            onClick={handleAddEditIssueType}
          >
            {modalTitle === ADD_BUSINESS_TYPE ||
            modalTitle === t("business_type_add")
              ? t("business_type_add")
              : t("update")}
          </Button>,
        ]}
      >
        <Form
          name="add-business-type-form"
          layout="vertical"
          onFinish={handleAddEditIssueType}
          style={{ width: "100%", margin: "0" }}
          form={businessTypeForm}
        >
          <Form.Item
            name="type"
            label={t("type")}
            rules={[
              {
                required: true,
                message: t("validate_business_types_required"),
              },
              {
                min: 3,
                message: t("validate_business_types_min_characters"),
              },
            ]}
            hasFeedback
          >
            <Input />
          </Form.Item>
          <Form.Item name="id" style={{ display: "none" }}>
            <input type="hidden" />
          </Form.Item>
        </Form>
      </Modal>
    </>
  );
};

export default BusinessTypeVerifications;
