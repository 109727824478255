import React from "react";

const CustomImageBox = ({ image, title, value }) => {
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "row",
        columnGap: "15px",
        alignItems: "center",
        padding: "20px",
        backgroundColor: "#ffffff",
      }}
    >
      <img src={image} alt={title} style={{ width: "70px", height: "auto" }} />
      <div>
        <div>{title}</div>
        <div style={{ fontSize: "24px", color: "rgb(99, 1, 242)" }}>
          {value}
        </div>
      </div>
    </div>
  );
};

export default CustomImageBox;
