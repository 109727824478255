import { useEffect, useState } from "react";
import {
  Form,
  Button,
  Table,
  Modal,
  Input,
  DatePicker,
  Row,
  Col,
  Popconfirm,
  InputNumber,
  Radio,
  Tag,
} from "antd";
import { useTranslation } from "react-i18next";
import moment from "moment";
import {
  createDevice,
  deleteDevice,
  getCompany,
  updateDevice,
} from "../../../network/network";
import NotificationWithIcon from "../../../components/NotificationWithIcon";
import { DeleteTwoTone, EditOutlined } from "@ant-design/icons";
import { currencyFormatter } from "../../../utils";

const DevicesTableWithAddModal = ({ companyId, reloadCallback, isDisabled = false }) => {
  const { t } = useTranslation();
  const ADD_DEVICE = t("device_add");
  const [loading, setLoading] = useState(false);
  const [modalVisible, setModalVisible] = useState(false);
  const [reloadTableData, setReloadTableData] = useState(false);
  const [deviceTable, setDeviceTable] = useState([]);
  const [installmentsChecked, setInstallmentsChecked] = useState(true);
  const [modalTitle, setModalTitle] = useState(ADD_DEVICE);
  const [deviceForm] = Form.useForm();

  useEffect(() => {
    // Set Loading true
    setLoading(true);
    getCompany(companyId)
      .then((response) => {
        setDeviceTable(response.data.data.company.devices);
      })
      .finally(() => {
        // Set Loading False
        setLoading(false);
      });
  }, [reloadTableData, companyId]);

  const DevicesTableColumns = [
    {
      title: "#",
      key: "index",
      render: (text, record, index) => {
        return <p>{index + 1}</p>;
      },
    },
    {
      title: t("model"),
      dataIndex: "model",
    },
    {
      title: t("imei"),
      dataIndex: "imei",
    },
    {
      title: t("serial_no"),
      dataIndex: "serial_no",
    },
    {
      title: t("amount"),
      dataIndex: "amount",
      render: (amount) => {
        return currencyFormatter.format(amount);
      },
    },
    {
      title: t("due_amount"),
      dataIndex: "due_amount",
      render: (due_amount) => {
        return currencyFormatter.format(due_amount);
      },
    },
    {
      title: t("installments"),
      dataIndex: "installments",
      render: (installments) => {
        return (
          <Tag color="blue">
            {installments === 1
              ? "One Time Payment"
              : installments + " Months Plan"}
          </Tag>
        );
      },
    },
    {
      title: t("col_payment"),
      dataIndex: "due_amount",
      render: (due_amount) => {
        return (
          <>
            {due_amount === 0 ? (
              <Tag color="green">Complete</Tag>
            ) : (
              <Tag color="red">Incomplete</Tag>
            )}
          </>
        );
      },
    },
    {
      title: t("warranty_from_to"),
      align: "center",
      render: (devices) => {
        return (
          <>
            {moment(devices.warranty_starting_at).format("MMM. D, YYYY")}
            <div>-</div>
            {moment(devices.warranty_ending_at).format("MMM. D, YYYY")}
          </>
        );
      },
    },
    {
      title: t("col_action"),
      align: "center",
      width: "100px",
      render: (devices) => {
        return (
          <>
            <Button type="link" onClick={() => handleDeviceEdit(devices)} disabled={isDisabled}>
              <EditOutlined />
            </Button>
            <Popconfirm
              title={t("warning_delete")}
              onConfirm={() => handleDeleteDevice(devices.id)}
              okText={t("yes")}
              cancelText={t("no")}
              disabled={isDisabled}
            >
              <DeleteTwoTone
                twoToneColor="#ff0000"
                title={t("delete_entity")}
              />
            </Popconfirm>
          </>
        );
      },
    },
  ];

  const handleAddDeviceBtn = () => {
    setModalTitle(t("device_add"));
    // Set Form Fields
    deviceForm.setFieldsValue({
      one_time_payment: true,
    });
    // show add device modal
    setModalVisible(true);
  };
  const handleDeviceAdd = () => {
    deviceForm
      .validateFields()
      .then((values) => {
        // Update Dates Value to 12pm wherever they are
        values.warranty_starting_at = values.warranty_starting_at.set({
          hour: 12,
          minute: 0,
          second: 0,
          millisecond: 0,
        });
        values.warranty_ending_at = values.warranty_ending_at.set({
          hour: 12,
          minute: 0,
          second: 0,
          millisecond: 0,
        });
        if (values.one_time_payment) {
          values.installments = 1;
        }
        // Set Loading True
        setLoading(true);
        if (modalTitle === ADD_DEVICE) {
          // Sending Request to API
          createDevice(values, companyId)
            .then((response) => {
              if (response?.data?.success === true) {
                setReloadTableData(!reloadTableData);
                // Reload Callback
                reloadCallback();
                // Success
                NotificationWithIcon(
                  "success",
                  t("device_added"),
                  t("device_added_successfully")
                );
                // Reset Form Fields
                deviceForm.resetFields();
                // Hide Modal
                setModalVisible(false);
              } else {
                let errors = response?.response?.data?.data?.errors;
                if (Object.keys(errors).length) {
                  // Error
                  NotificationWithIcon(
                    "error",
                    t("something_went_wrong"),
                    errors[Object.keys(errors)[0]]
                  );
                }
              }
            })
            .finally(() => {
              // Set Loading False
              setLoading(false);
            });
        } else {
          updateDevice(values)
            .then((response) => {
              if (response.data.success === true) {
                setReloadTableData(!reloadTableData);
                // Reload Callback
                reloadCallback();
                // Success
                NotificationWithIcon(
                  "success",
                  t("device_updated"),
                  t("device_updated_successfully")
                );
              } else {
                // Error
                NotificationWithIcon(
                  "error",
                  t("something_went_wrong"),
                  response.data.message
                );
              }
              // Reset Form Fields
              deviceForm.resetFields();
              // Hide Modal
              setModalVisible(false);
            })
            .catch((info) => {
              console.log("API Failed:", info.response);
              // Error
              NotificationWithIcon(
                "error",
                t("something_went_wrong"),
                info.response.data.data.errors.name
              );
            })
            .finally(() => {
              // Set Loading False
              setLoading(false);
            });
        }
      })
      .catch((info) => {
        console.log("Validate Failed:", info);
      });
  };

  const handleCancel = () => {
    // Reset Form Fields
    deviceForm.resetFields();
    // Hide Modal
    setModalVisible(false);
    //hide instalments
    setInstallmentsChecked(true);
  };

  const handleDeviceEdit = (device) => {
    // Set Modal Title
    setModalTitle(t("edit") + " : " + device.model);
    // Set Form Fields
    device.deviceId = device.id;
    device.warranty_starting_at = moment(device.warranty_starting_at);
    device.warranty_ending_at = moment(device.warranty_ending_at);
    deviceForm.setFieldsValue(device);
    // Show Modal
    setModalVisible(true);
  };

  const handleDeleteDevice = (id) => {
    deleteDevice(id)
      .then((response) => {
        if (response.data.success === true) {
          setReloadTableData(!reloadTableData);
          // Reload Callback
          reloadCallback();
          // Success
          NotificationWithIcon(
            "success",
            t("device_deleted"),
            t("device_deleted_successfully")
          );
        } else {
          // Error
          NotificationWithIcon(
            "error",
            t("something_went_wrong"),
            response.data.message
          );
        }
      })
      .catch((info) => {
        console.log("API Failed:", info.response);
        // Error
        NotificationWithIcon(
          "error",
          t("something_went_wrong"),
          info.response.data.data.errors.name
        );
      })
      .finally(() => {
        // Set Loading False
        setLoading(false);
      });
  };

  const installmentsOptions = [
    {
      label: "2 Months",
      value: 2,
    },
    {
      label: "4 Months",
      value: 4,
    },
    {
      label: "6 Months",
      value: 6,
    },
    {
      label: "8 Months",
      value: 8,
    },
    {
      label: "10 Months",
      value: 10,
    },
    {
      label: "12 Months",
      value: 12,
    },
  ];

  return (
    <>
      <div style={{ marginBottom: "20px", textAlign: "right" }}>
        <Button type="primary" onClick={handleAddDeviceBtn} disabled={isDisabled}>
          {t("device_add")}
        </Button>
      </div>

      <Table
        columns={DevicesTableColumns}
        dataSource={deviceTable}
        loading={loading}
        rowKey="id"
        pagination={true}
      />
      <Modal
        visible={modalVisible}
        title={modalTitle}
        onOk={handleDeviceAdd}
        onCancel={handleCancel}
        footer={[
          <Button key="back" loading={loading} onClick={handleCancel}>
            {t("cancel")}
          </Button>,
          <Button
            key="submit"
            type="primary"
            loading={loading}
            onClick={handleDeviceAdd}
          >
            {modalTitle === ADD_DEVICE ? t("device_add") : t("update")}
          </Button>,
        ]}
      >
        <Form
          name="devices-add-form"
          layout="vertical"
          onFinish={handleDeviceAdd}
          style={{ width: "100%", margin: "0" }}
          form={deviceForm}
        >
          <Form.Item
            name="model"
            label={t("model")}
            rules={[
              {
                required: true,
                message: t("validate_model_required"),
                whitespace: true,
              },
            ]}
            hasFeedback
          >
            <Input />
          </Form.Item>
          <Form.Item
            name="imei"
            label={t("imei")}
            rules={[
              {
                required: true,
                message: t("validate_imei_required"),
                whitespace: true,
              },
            ]}
            hasFeedback
          >
            <Input />
          </Form.Item>
          <Form.Item
            name="serial_no"
            label={t("serial_no")}
            rules={[
              {
                required: true,
                message: t("validate_serial_no_required"),
                whitespace: true,
              },
            ]}
            hasFeedback
          >
            <Input />
          </Form.Item>
          <Row gutter={20}>
            <Col span={12}>
              <Form.Item
                name="warranty_starting_at"
                label={t("warranty_starting_at")}
                rules={[
                  {
                    required: true,
                    message: t("validate_warranty_starting_required"),
                  },
                ]}
                hasFeedback
              >
                <DatePicker
                  allowClear
                  format="DD-MM-YYYY"
                  style={{ width: "100%" }}
                />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                name="warranty_ending_at"
                label={t("warranty_ending_at")}
                rules={[
                  {
                    required: true,
                    message: t("validate_warranty_ending__required"),
                  },
                ]}
                hasFeedback
              >
                <DatePicker
                  allowClear
                  format="DD-MM-YYYY"
                  style={{ width: "100%" }}
                />
              </Form.Item>
            </Col>
          </Row>
          <Form.Item
            name="amount"
            label={t("device_amount")}
            rules={[
              {
                required: true,
                message: t("validate_amount_required"),
              },
            ]}
            hasFeedback
          >
            <InputNumber
              min={1}
              style={{ width: "100%" }}
              disabled={modalTitle === ADD_DEVICE ? false : true}
            />
          </Form.Item>

          {modalTitle === ADD_DEVICE && (
            <>
              <Form.Item name="one_time_payment">
                <Radio.Group
                  // defaultValue={true}
                  onChange={(e) => {
                    setInstallmentsChecked(e.target.value);
                  }}
                >
                  <Radio value={true}>One time Payment</Radio>
                  <Radio value={false}>Installments</Radio>
                </Radio.Group>
              </Form.Item>

              {!installmentsChecked && (
                <Form.Item
                  name="installments"
                  rules={[
                    {
                      required: true,
                      message: "Please select Installments.",
                    },
                  ]}
                >
                  <Radio.Group options={installmentsOptions} />
                </Form.Item>
              )}
            </>
          )}

          {modalTitle !== ADD_DEVICE && (
            <Form.Item name="deviceId" hidden={true}>
              <input />
            </Form.Item>
          )}
        </Form>
      </Modal>
    </>
  );
};

export default DevicesTableWithAddModal;
