import "./Dashboard.css";
import React, { useEffect, useState, useContext } from "react";
import { Menu, Layout } from "antd";
import {
  MenuUnfoldOutlined,
  MenuFoldOutlined,
  DownOutlined,
  ArrowLeftOutlined,
} from "@ant-design/icons";
import { AppContext } from "../../context/AppContextProvider";
import Routes, { ContentPages } from "../../routes";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import {
  getNotifications,
  updateAuthDetails,
  userLogout,
} from "../../network/network";
import { filterPagesByAddons } from "../../utils";
import NotificationsDropdown from "../../components/NotificationsDropdown";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";

const { Header, Sider, Content, Footer } = Layout;
const { SubMenu } = Menu;

const Dashboard = () => {
  const {
    authUser,
    activeMenu,
    setActiveMenu,
    appConfig,
    setAppConfigWrapper,
  } = useContext(AppContext);
  const [isSiderCollapsed, setIsSiderCollapsed] = useState(false);
  const [unReadNotificationsCount, setUnreadNotificationsCount] = useState(0);
  const [notifications, setNotifications] = useState([]);
  const { t } = useTranslation();
  const history = useHistory();

  useEffect(() => {
    setAppConfigWrapper(authUser.app_config);
    // eslint-disable-next-line
  }, [
    authUser.business_name,
    authUser.business_type,
    authUser.cr,
    authUser.vat,
    authUser.address,
    authUser.logo,
    authUser.app_config,
  ]);

  useEffect(() => {
    if (
      authUser &&
      Object.keys(appConfig).length > 0 &&
      JSON.stringify(authUser.app_config) !== JSON.stringify(appConfig)
    ) {
      const formData = new FormData();
      formData.append("app_config", JSON.stringify(appConfig));
      updateAuthDetails(formData).then((res) => {});
    }
  }, [appConfig, authUser]);

  useEffect(() => {
    if (authUser !== null) {
      const privateChannel = window.Echo.private(
        "App.Models.User." + authUser.id
      );

      const handleNotification = (notification) => {
        setUnreadNotificationsCount((count) => count + 1);
        setNotifications((notifs) => [notification, ...notifs]);
      };

      privateChannel.notification(handleNotification);

      return () => {
        privateChannel.stopListening(".notification");
      };
    }
  }, [authUser]);

  useEffect(() => {
    setUnreadNotificationsCount(authUser.unread_notifications_count);
    setNotifications(authUser.notifications?.notifications ?? []);
  }, [authUser]);

  const changeUnreadNotificationsCount = (value) => {
    setUnreadNotificationsCount((prevCount) => value);
  };

  const handleLoadMore = (page) => {
    getNotifications(page)
      .then((response) => {
        setNotifications((prevNotifications) => [
          ...prevNotifications,
          ...response.data?.data?.notifications,
        ]);
      })
      .catch((info) => {
        // Error
        console.log(
          "Getting more notifications error: ",
          info.response.message
        );
      });
  };

  const saveCurrentPageView = (key) => {
    setActiveMenu(key);
    localStorage.setItem(
      "last_visited_page",
      JSON.stringify({
        key: key,
        path: window.location.pathname,
      })
    );
  };

  const logoutUser = () => {
    userLogout().then(function () {
      localStorage.removeItem("last_visited_page");
      localStorage.removeItem("access_token");
      window.location = "/login";
    });
  };

  return (
    <Layout id="app" hasSider={true}>
      <Sider
        collapsible
        theme="light"
        trigger={null}
        defaultCollapsed={false}
        collapsed={isSiderCollapsed}
        collapsedWidth={window.innerWidth > 767 ? 100 : 0}
        // onCollapse={this.onCollapse}
        width={220}
        breakpoint={"md"}
        style={{ boxShadow: "0 0 30px -10px black", minHeight: "100vh" }}
      >
        <div style={{ padding: "30px 10px", textAlign: "center" }}>
          <img src="/logo.png" alt="AnyPOS Logo" width="60%" height="auto" />
        </div>
        <Menu theme="light" mode="inline" selectedKeys={[activeMenu]}>
          {filterPagesByAddons(ContentPages, authUser).map(function (page, i) {
            if (page.showInSider) {
              return page.subMenus && page.subMenus.length > 0 ? (
                <Menu.SubMenu
                  key={page.name}
                  icon={React.createElement(page.icon)}
                  title={t(page.text)}
                >
                  {filterPagesByAddons(page.subMenus, authUser).map(function (
                    subPage,
                    j
                  ) {
                    if (subPage.showInSider) {
                      return (
                        <Menu.Item
                          key={subPage.name}
                          icon={React.createElement(subPage.icon)}
                          onClick={() => saveCurrentPageView(subPage.name)}
                        >
                          <Link to={subPage.path}>{t(subPage.text)}</Link>
                        </Menu.Item>
                      );
                    }
                    return false;
                  })}
                </Menu.SubMenu>
              ) : (
                <Menu.Item
                  key={page.name}
                  icon={React.createElement(page.icon)}
                  onClick={() => saveCurrentPageView(page.name)}
                >
                  <Link to={page.path}>{t(page.text)}</Link>
                </Menu.Item>
              );
            }
            return false;
          })}
        </Menu>
      </Sider>
      <Layout className="site-layout">
        <Header
          className="site-layout-background"
          style={{
            padding: "0 10px",
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <Menu
            theme="light"
            mode="horizontal"
            style={{
              borderBottom: "none",
              minWidth: "300px",
            }}
          >
            <Menu.Item
              key="sidebar-collapse-menu-item"
              style={{ padding: "0 10px" }}
            >
              <span
                onClick={() => setIsSiderCollapsed(!isSiderCollapsed)}
                style={{
                  lineHeight: "normal",
                  border: "1px solid",
                  width: "40px",
                  height: "40px",
                  padding: "0",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  borderRadius: "35rem",
                  marginTop: "calc( 50% - 8px )",
                }}
              >
                {isSiderCollapsed ? (
                  <MenuUnfoldOutlined />
                ) : (
                  <MenuFoldOutlined />
                )}
              </span>
            </Menu.Item>
            <Menu.Item key="history-back-button" style={{ padding: "0 10px" }}>
              <span
                onClick={() => history.goBack()}
                style={{
                  lineHeight: "normal",
                  border: "1px solid",
                  width: "40px",
                  height: "40px",
                  padding: "0",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  borderRadius: "35rem",
                  marginTop: "calc( 50% - 8px )",
                }}
              >
                <ArrowLeftOutlined />
              </span>
            </Menu.Item>
            {/* <Menu.Item
              key="top-navbar-0"
              style={{
                borderBottom: "none",
              }}
            >
              <Radio.Group
                value={appConfig.direction}
                onChange={(e) => {
                  setAppConfigWrapper({ direction: e.target.value });
                }}
                buttonStyle="solid"
              >
                <Radio.Button value="ltr">English</Radio.Button>
                <Radio.Button value="rtl">عربي</Radio.Button>
              </Radio.Group>
            </Menu.Item> */}
          </Menu>
          <Menu
            theme="light"
            mode="horizontal"
            style={{
              borderBottom: "none",
              display: "block",
              minWidth: "300px",
              textAlign: appConfig.direction === "rtl" ? "left" : "right",
            }}
          >
            {/* <Menu.Item key="top-navbar-1">
              <MailOutlined />
            </Menu.Item>
            <Menu.Item key="top-navbar-2">
              <BellOutlined />
            </Menu.Item> */}
            <Menu.Item key="top-navbar-2">
              {/* <Badge count={unReadNotificationsCount}>
                    <BellOutlined style={{ fontSize: '20px' }} />
                </Badge> */}
              <NotificationsDropdown
                notifications={notifications}
                unReadNotificationsCount={unReadNotificationsCount}
                changeUnreadNotificationsCount={changeUnreadNotificationsCount}
                onLoadMore={handleLoadMore}
              />
            </Menu.Item>
            <SubMenu
              key="top-navbar-3"
              title={
                <span
                  className="submenu-title-wrapper"
                  style={{ width: "150px" }}
                >
                  {authUser.name + " "}
                  <DownOutlined />
                </span>
              }
            >
              <Menu.Item key="31" onClick={logoutUser}>
                {t("logout")}
              </Menu.Item>
            </SubMenu>
          </Menu>
        </Header>
        <Content
          className="site-layout-background"
          style={{
            margin: "24px 16px",
            padding: "0",
            background: "transparent",
            minHeight: "80vh",
          }}
        >
          <Routes />
        </Content>
        <Footer
          style={{
            textAlign: "center",
            backgroundColor: "#ffffff",
            fontWeight: "500",
            padding: "10px",
          }}
        >
          AnyPOS © {new Date().getFullYear()}
        </Footer>
      </Layout>
    </Layout>
  );
};

export default Dashboard;
