import { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { getCompany, updateCompany } from "../../../network/network";
import { PageHeader, Card, Form, Button, Checkbox, Tabs, Tag } from "antd";
import {
  HomeOutlined,
  AppstoreOutlined,
  RightOutlined,
  LeftOutlined,
} from "@ant-design/icons";
import CustomBreadcrumb from "../../../components/CustomBreadcrumb";
import NotificationWithIcon from "../../../components/NotificationWithIcon";
import { useTranslation } from "react-i18next";
import BranchesTableWithEditModal from "../CompanyDetails/BranchesTableWithEditModal";
import EmployeesTableWithEditModal from "../CompanyDetails/EmployeesTableWithEditModal";
import SubscriptionsTable from "../CompanyDetails/SubscriptionsTable";
import DevicesTableWithAddModal from "../CompanyDetails/DevicesTableWithAddModal";
import InvoicesTable from "../CompanyDetails/InvoicesTable";
import CompanyGeneralInfo from "../CompanyDetails/CompanyGeneralInfo";
import CustomerInfo from "../CompanyDetails/CustomerInfo";
import {
  useHistory,
  useLocation,
} from "react-router-dom/cjs/react-router-dom.min";

const Company = () => {
  const [reload, setReload] = useState(false);
  const [company, setCompany] = useState(false);
  const [nextCompanyId, setNextCompanyId] = useState(false);
  const [previousCompanyId, setPreviousCompanyId] = useState(false);
  const [isDisabled, setIsDisabled] = useState(false);

  const [addonsForm] = Form.useForm();
  const { t } = useTranslation();
  let { companyId } = useParams();
  const [CID, setCID] = useState(companyId);
  const history = useHistory();
  const location = useLocation();
  const { search } = location;
  const [searchParams, setSearchParams] = useState(search);

  useEffect(() => {
    setSearchParams(searchParams);
    // if (searchParams) {
    //   history.push({
    //     pathname: "/companies/" + CID,
    //     search: searchParams.toString(),
    //   });
    // } else {
    //   history.push({
    //     pathname: "/companies/" + CID,
    //   });
    // }
    let formData = searchParams ? CID + searchParams : CID;

    getCompany(formData).then((response) => {
      setCompany(response.data.data.company);
      setNextCompanyId(response.data.data.next_company_id);
      setPreviousCompanyId(response.data.data.prev_company_id);
    });
  }, [CID, reload, searchParams, history]);

  useEffect(() => {
    setIsDisabled(!company.is_active);
  }, [company]);

  const handleAddOns = () => {
    let addons = addonsForm.getFieldValue("addons");
    if (!addons) {
      addons = [];
    }

    let data = {
      id: company.id,
      addons: JSON.stringify(addons),
    };

    updateCompany(data).then((response) => {
      if (response.data.success === true) {
        NotificationWithIcon(
          "success",
          t("company_updated"),
          t("company_updated_successfully")
        );
      } else {
        // Error
        NotificationWithIcon(
          "error",
          t("something_went_wrong"),
          response.data.message
        );
      }
      setReload(!reload);
    });
  };

  const tabChange = (tab) => {
    if (tab === "tab-addons") {
      addonsForm.setFieldsValue({ addons: company.addons });
    }
  };

  const breadcrumbRoutes = [
    {
      path: "home",
      breadcrumbName: t("menu_home"),
      icon: <HomeOutlined />,
    },
    {
      path: "/companies",
      breadcrumbName: t("menu_companies"),
      icon: <AppstoreOutlined />,
    },
    {
      path: "",
      breadcrumbName: company ? company.name : "",
      icon: false,
    },
  ];

  return (
    <>
      <CustomBreadcrumb routes={breadcrumbRoutes} />
      {company && (
        <>
          <PageHeader
            className="site-page-header"
            title={t("company") + " : " + company.name}
            avatar={{
              src: company.logo ? company.logo : "/images/image-not-found.png",
            }}
            tags={
              <Tag
                color={
                  company.last_active_at === "Unknown"
                    ? "red"
                    : company.last_active_at === "Today"
                    ? "green"
                    : "blue"
                }
              >
                Last Active: {company.last_active_at}
              </Tag>
            }
            ghost={false}
            extra={[
              <Button
                title="Next"
                onClick={() => {
                  setCID(nextCompanyId);
                  history.push({
                    pathname: "/companies/" + nextCompanyId,
                    search: searchParams.toString(),
                  });
                }}
                disabled={nextCompanyId ? false : true}
              >
                <LeftOutlined />
              </Button>,
              <Button
                title="Previous"
                onClick={() => {
                  setCID(previousCompanyId);
                  history.push({
                    pathname: "/companies/" + previousCompanyId,
                    search: searchParams.toString(),
                  });
                }}
                disabled={previousCompanyId ? false : true}
              >
                <RightOutlined />
              </Button>,
            ]}
          />
          <Card>
            <Tabs defaultActiveKey="tab-general" onChange={tabChange}>
              <Tabs.TabPane tab="General" key="tab-general">
                <CompanyGeneralInfo />
              </Tabs.TabPane>

              <Tabs.TabPane tab="Customer info" key="tab-customer-info">
                <CustomerInfo
                  company={company}
                  reloadCallback={() => setReload(!reload)}
                />
              </Tabs.TabPane>

              <Tabs.TabPane tab="Addons" key="tab-addons">
                <Form
                  name="manage-addons-form"
                  layout="vertical"
                  onFinish={handleAddOns}
                  style={{ width: "100%", margin: "0" }}
                  form={addonsForm}
                  disabled={isDisabled}
                >
                  <Form.Item name="addons" label={t("addons")}>
                    <Checkbox.Group
                      options={[
                        { label: "POS", value: "pos" },
                        { label: "Multi-Branch", value: "multi_branch" },
                      ]}
                    />
                  </Form.Item>

                  <Button type="primary" htmlType="submit">
                    Update Addons
                  </Button>
                </Form>
              </Tabs.TabPane>
              <Tabs.TabPane tab="Branches" key="tab-branches">
                <BranchesTableWithEditModal
                  company={company}
                  branches={company.branches}
                  reloadCallback={() => setReload(!reload)}
                  isDisabled={isDisabled}
                />
              </Tabs.TabPane>
              <Tabs.TabPane tab="Users" key="tab-users">
                <EmployeesTableWithEditModal employees={company.employees} />
              </Tabs.TabPane>
              <Tabs.TabPane tab="Subscriptions" key="tab-subscriptions">
                <SubscriptionsTable
                  subscriptions={company.subscriptions}
                  company={company}
                  reloadCallback={() => setReload(!reload)}
                  isDisabled={isDisabled}
                />
              </Tabs.TabPane>
              <Tabs.TabPane tab="Invoices" key="tab-invoices">
                <InvoicesTable
                  company={company}
                  reloadCallback={() => setReload(!reload)}
                  isDisabled={isDisabled}
                />
              </Tabs.TabPane>
              <Tabs.TabPane tab="Devices" key="tab-devices">
                <DevicesTableWithAddModal
                  companyId={company.id}
                  reloadCallback={() => setReload(!reload)}
                  isDisabled={isDisabled}
                />
              </Tabs.TabPane>
            </Tabs>
          </Card>
        </>
      )}
    </>
  );
};

export default Company;
