import React, { useEffect, useState } from "react";
import {
  PageHeader,
  Card,
  Form,
  Input,
  Button,
  Table,
  Modal,
  Radio,
} from "antd";
import {
  HomeOutlined,
  EditTwoTone,
  MailOutlined,
  UserSwitchOutlined,
  CheckCircleOutlined,
  PhoneOutlined,
  ClockCircleOutlined,
  CalendarOutlined,
  CalculatorOutlined,
  FormOutlined,
  ReadOutlined,
  MobileOutlined,
  CreditCardOutlined,
  BellOutlined,
  MessageOutlined,
  CameraOutlined,
  FolderOpenOutlined,
  StopOutlined,
  GlobalOutlined,
} from "@ant-design/icons";
import {
  getActivityTypes,
  createActivityType,
  updateActivityType,
} from "../../../network/network";
import CustomBreadcrumb from "../../../components/CustomBreadcrumb";
import NotificationWithIcon from "../../../components/NotificationWithIcon";
import { useTranslation } from "react-i18next";
import FindActivityTypeIcon from "../../../components/FindActivityTypeIcon";

const ActivityType = () => {
  const ADD_ACTIVITY_TYPE = "Add Activity Type";
  const [modalTitle, setModalTitle] = useState(ADD_ACTIVITY_TYPE);
  const [modalVisible, setModalVisible] = useState(false);
  const [loading, setLoading] = useState(false);
  const [isDataLoading, setIsDataLoading] = useState(false);
  const [reloadTableData, setReloadTableData] = useState(false);
  const [activityTypeTableData, setActivityTypeTableData] = useState([]);
  const [activityType, setActivityType] = useState("");
  const [activityTypeForm] = Form.useForm();
  const { t } = useTranslation();

  const radioStyle = {
    margin: "5px",
    fontSize: "18px",
    height: "auto",
    lineHeight: "unset",
  };

  const activityIcons = [
    {
      label: <MailOutlined />,
      value: "MailOutlined",
      style: radioStyle,
    },
    {
      label: <FormOutlined />,
      value: "FormOutlined",
      style: radioStyle,
    },
    {
      label: <PhoneOutlined />,
      value: "PhoneOutlined",
      style: radioStyle,
    },
    {
      label: <CalendarOutlined />,
      value: "CalendarOutlined",
      style: radioStyle,
    },
    {
      label: <CalculatorOutlined />,
      value: "CalculatorOutlined",
      style: radioStyle,
    },
    {
      label: <BellOutlined />,
      value: "BellOutlined",
      style: radioStyle,
    },
    {
      label: <MessageOutlined />,
      value: "MessageOutlined",
      style: radioStyle,
    },
    {
      label: <CheckCircleOutlined />,
      value: "CheckCircleOutlined",
      style: radioStyle,
    },
    {
      label: <ReadOutlined />,
      value: "ReadOutlined",
      style: radioStyle,
    },
    {
      label: <CameraOutlined />,
      value: "CameraOutlined",
      style: radioStyle,
    },
    {
      label: <FolderOpenOutlined />,
      value: "FolderOpenOutlined",
      style: radioStyle,
    },
    {
      label: <MobileOutlined />,
      value: "MobileOutlined",
      style: radioStyle,
    },
    {
      label: <CreditCardOutlined />,
      value: "CreditCardOutlined",
      style: radioStyle,
    },
    {
      label: <UserSwitchOutlined />,
      value: "UserSwitchOutlined",
      style: radioStyle,
    },
    {
      label: <ClockCircleOutlined />,
      value: "ClockCircleOutlined",
      style: radioStyle,
    },
    {
      label: <StopOutlined />,
      value: "StopOutlined",
      style: radioStyle,
    },
  ];

  useEffect(() => {
    setIsDataLoading(true);
    getActivityTypes()
      .then((response) => {
        setActivityTypeTableData(response.data.data.activity_types);
      })
      .finally(() => {
        setIsDataLoading(false);
      });
  }, [reloadTableData]);

  const showAddModal = () => {
    // Set Modal Title
    setModalTitle(t("activity_type_add"));
    // Reset Form Fields
    activityTypeForm.resetFields();
    // Show Modal
    setModalVisible(true);
  };

  const handleAPIResponse = (data, type) => {
    if (data.success === true) {
      setReloadTableData(!reloadTableData);
      // Success
      if (type === ADD_ACTIVITY_TYPE) {
        NotificationWithIcon(
          "success",
          t("activity_type_added"),
          t("activity_type_added_successfully")
        );
      } else {
        NotificationWithIcon(
          "success",
          t("activity_type_updated"),
          t("activity_type_updated_successfully")
        );
      }
    } else {
      // Error
      NotificationWithIcon("error", t("something_went_wrong"), data.message);
    }
    // Reset Form Fields
    activityTypeForm.resetFields();
    // Hide Modal
    setModalVisible(false);
    setActivityType(false);
  };

  const handleAddEdit = () => {
    if (activityType) {
      activityTypeForm.setFieldsValue({
        icon: activityType,
      });
    }
    activityTypeForm
      .validateFields()

      .then((values) => {
        console.log(values);
        // Set Loading True
        setLoading(true);
        // Sending Request to API
        if (
          modalTitle === ADD_ACTIVITY_TYPE ||
          modalTitle === t("activity_type_add")
        ) {
          createActivityType(values)
            .then((response) => {
              let data = response.data || response.response.data;
              if (data.success === true) {
                return handleAPIResponse(data, ADD_ACTIVITY_TYPE);
              } else {
                let errors = data?.data?.errors;
                if (errors && Object.keys(errors).length) {
                  // Error
                  NotificationWithIcon(
                    "error",
                    t("something_went_wrong"),
                    errors[Object.keys(errors)[0]]
                  );
                }
              }
            })
            .finally(() => {
              // Set Loading False
              setLoading(false);
            });
        } else {
          updateActivityType(values)
            .then((response) => {
              let data = response.data || response.response.data;
              if (data.success === true) {
                return handleAPIResponse(data, null);
              } else {
                let errors = data?.data?.errors;
                if (errors && Object.keys(errors).length) {
                  // Error
                  NotificationWithIcon(
                    "error",
                    t("something_went_wrong"),
                    errors[Object.keys(errors)[0]]
                  );
                }
              }
            })
            .finally(() => {
              // Set Loading False
              setLoading(false);
            });
        }
      })
      .catch((info) => {
        console.log("Validate Failed:", info);
      });
  };

  const handleEditModal = (activity_type_id) => {
    // Find Activity Type from DataArray
    const activityType = activityTypeTableData.find(
      (activityType) => activityType.id === activity_type_id
    );
    // Set Modal Title
    setModalTitle(t("activity_type_edit") + " : " + activityType.title);
    setActivityType(activityType.icon.value);
    // Show Modal
    setModalVisible(true);
    // Set Form Fields
    activityTypeForm.setFieldsValue({
      id: activityType.id,
      title: activityType.title,
      icon: activityType.icon,
    });
  };

  const handleCancel = () => {
    // Reset Form Fields
    activityTypeForm.resetFields();
    setModalVisible(false);
    setActivityType(false);
  };

  const breadcrumbRoutes = [
    {
      path: "home",
      breadcrumbName: t("menu_home"),
      icon: <HomeOutlined />,
    },
    {
      path: "activity-type",
      breadcrumbName: t("menu_activity_type"),
      icon: <GlobalOutlined />,
    },
  ];

  const ActivityTypeTableColumns = [
    {
      title: "#",
      key: "index",
      width: "50px",
      render: (text, record, index) => {
        return <>{index + 1}</>;
      },
    },
    {
      title: t("icon"),
      align: "center",
      width: "100px",
      render: (record) => {
        return (
          <>
            <FindActivityTypeIcon name={record.icon} title={record.title} />
          </>
        );
      },
    },
    {
      title: t("title"),
      dataIndex: "title",
    },
    {
      title: t("col_action"),
      align: "center",
      width: "100px",
      render: (record) => {
        return (
          <>
            <Button
              type="link"
              onClick={() => handleEditModal(record.id)}
              title={t("activity_type_edit")}
            >
              <EditTwoTone twoToneColor="#1890ff" />
            </Button>
            {/* <Popconfirm
              title={t("warning_delete")}
              onConfirm={() => handledelete(record.id)}
              okText={t("yes")}
              cancelText={t("no")}
            >
              <DeleteTwoTone
                twoToneColor="#ff0000"
                title={t("delete_entity")}
              />
            </Popconfirm> */}
          </>
        );
      },
    },
  ];

  return (
    <>
      <CustomBreadcrumb routes={breadcrumbRoutes} />
      <PageHeader
        className="site-page-header"
        title={t("menu_activity_type")}
        ghost={false}
        extra={[
          <Button
            key="open-add-activity-type-modal"
            type="primary"
            onClick={showAddModal}
          >
            + {t("activity_type_add")}
          </Button>,
        ]}
      />
      <Card>
        <Table
          columns={ActivityTypeTableColumns}
          dataSource={activityTypeTableData}
          loading={isDataLoading}
          rowKey="id"
          pagination={false}
        />
      </Card>
      <Modal
        visible={modalVisible}
        title={modalTitle}
        onOk={handleAddEdit}
        onCancel={handleCancel}
        footer={[
          <Button key="back" loading={loading} onClick={handleCancel}>
            {t("cancel")}
          </Button>,
          <Button
            key="submit"
            type="primary"
            loading={loading}
            onClick={handleAddEdit}
          >
            {modalTitle === ADD_ACTIVITY_TYPE ||
            modalTitle === t("activity_type_add")
              ? t("activity_type_add")
              : t("update")}
          </Button>,
        ]}
      >
        <Form
          name="add-activity-type-form"
          layout="vertical"
          onFinish={handleAddEdit}
          style={{ width: "100%", margin: "0" }}
          form={activityTypeForm}
        >
          <Form.Item
            name="title"
            label={t("title")}
            rules={[
              {
                required: true,
                message: t("validate_title_required"),
                whitespace: true,
              },
            ]}
            hasFeedback
          >
            <Input />
          </Form.Item>
          <Form.Item
            name="icon"
            label={t("icon")}
            rules={[
              {
                required: true,
                message: t("validate_icon_required"),
              },
            ]}
          >
            <Radio.Group
              onChange={(e) => {
                setActivityType(e.target.value);
              }}
              optionType="button"
              buttonStyle="solid"
              options={activityIcons}
            ></Radio.Group>
          </Form.Item>
          <Form.Item name="id" style={{ display: "none" }}>
            <input type="hidden" />
          </Form.Item>
        </Form>
      </Modal>
    </>
  );
};

export default ActivityType;
