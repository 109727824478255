import React, { useState, useEffect } from "react";
import {
  PageHeader,
  Card,
  Form,
  Input,
  Button,
  Table,
  Modal,
  Popconfirm,
  Radio,
  Tag,
  Select,
} from "antd";
import {
  HomeOutlined,
  GlobalOutlined,
  EditTwoTone,
  DeleteTwoTone,
} from "@ant-design/icons";
import {
  createRegion,
  getRegions,
  updateRegion,
  deleteRegion,
} from "../../../network/network";
import NotificationWithIcon from "../../../components/NotificationWithIcon";
import CustomBreadcrumb from "../../../components/CustomBreadcrumb";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

const Regions = () => {
  const ADD_REGION = "Add Region";
  const [modalTitle, setModalTitle] = useState([ADD_REGION]);
  const [modalVisible, setModalVisible] = useState();
  const [loading, setLoading] = useState(false);
  const [isDataLoading, setIsDataLoading] = useState(false);
  const [reloadTableData, setReloadTableData] = useState(false);
  const [regionsTableData, setRegionsTableData] = useState([]);
  const [regionForm] = Form.useForm();
  const { t } = useTranslation();

  const countries = [{ name: "KSA", id: 1 }];

  useEffect(() => {
    setIsDataLoading(true);
    getRegions()
      .then((response) => {
        setRegionsTableData(response.data.data.regions);
      })
      .finally(() => {
        setIsDataLoading(false);
      });
  }, [reloadTableData]);

  const showAddModal = () => {
    // set Modal Title
    setModalTitle(t("region_add"));

    // Reset Form Field
    regionForm.resetFields();

    // Show Modal
    setModalVisible(true);

    // Set Form Fields
    regionForm.setFieldsValue({
      is_active: true,
    });
  };

  const handleAPIResponse = (response, type) => {
    if (response.data.success === true) {
      setReloadTableData(!reloadTableData);
      // Success
      if (type === ADD_REGION) {
        NotificationWithIcon(
          "success",
          t("region_added"),
          t("region_added_successfully")
        );
      } else {
        NotificationWithIcon(
          "success",
          t("region_updated"),
          t("region_updated_successfully")
        );
      }
    } else {
      // Error
      NotificationWithIcon(
        "error",
        t("something_went_wrong"),
        response.data.message
      );
    }
    // Reset Form Fields
    regionForm.resetFields();
    // Hide Modal
    setModalVisible(false);
  };

  const handleAddEditRegion = () => {
    regionForm
      .validateFields()
      .then((values) => {
        // Set Loading True
        setLoading(true);

        // Sending Request to API
        if (modalTitle === ADD_REGION || modalTitle === t("region_add")) {
          createRegion(values)
            .then((response) => {
              let data = response.data || response.response.data;
              if (data.success === true) {
                return handleAPIResponse(response, ADD_REGION);
              } else {
                let errors = data?.data?.errors;
                if (errors && Object.keys(errors).length) {
                  // Error
                  NotificationWithIcon(
                    "error",
                    t("something_went_wrong"),
                    errors[Object.keys(errors)[0]]
                  );
                }
              }
            })
            .catch((info) => {
              console.log("API Failed:", info.response);
              // Error
              NotificationWithIcon(
                "error",
                t("something_went_wrong"),
                info.response.data.data.errors.name
              );
            })
            .finally(() => {
              // Set Loading False
              setLoading(false);
            });
        } else {
          updateRegion(values)
            .then((response) => {
              let data = response.data || response.response.data;
              if (data.success === true) {
                return handleAPIResponse(response, null);
              } else {
                let errors = data?.data?.errors;
                if (errors && Object.keys(errors).length) {
                  // Error
                  NotificationWithIcon(
                    "error",
                    t("something_went_wrong"),
                    errors[Object.keys(errors)[0]]
                  );
                }
              }
            })
            .catch((info) => {
              console.log("API Failed:", info.response);
              // Error
              NotificationWithIcon(
                "error",
                t("something_went_wrong"),
                info.response.data.message
              );
            })
            .finally(() => {
              // Set Loading False
              setLoading(false);
            });
        }
      })
      .catch((info) => {
        console.log("Validate Failed:", info);
      });
  };

  const handleEditModal = (region_id) => {
    // Find region from DataArray
    const region = regionsTableData.find((emp) => emp.id === region_id);

    // Set Modal Title
    setModalTitle(t("region_edit") + " : " + region.name_en);

    // Show Modal
    setModalVisible(true);

    // Set Form Fields
    regionForm.setFieldsValue({
      id: region.id,
      name_en: region.name_en,
      name_ar: region.name_ar,
      country_id: region.country.id,
      is_active: region.is_active,
    });
  };

  const handleCancel = () => {
    // Reset Form Fields
    regionForm.resetFields();
    setModalVisible(false);
  };

  const handleDeleteRegion = (id) => {
    deleteRegion(id).then((response) => {
      let data = response.data || response.response.data;
      if (data.success === true) {
        setReloadTableData(!reloadTableData);
        // Success
        NotificationWithIcon(
          "success",
          t("region_deleted"),
          t("region_deleted_successfully")
        );
      } else {
        // Error
        NotificationWithIcon("error", t("something_went_wrong"), data?.message);
      }
    });
  };

  const breadcrumbRoutes = [
    {
      path: "home",
      breadcrumbName: t("menu_home"),
      icon: <HomeOutlined />,
    },
    {
      path: "regions",
      breadcrumbName: t("menu_regions"),
      icon: <GlobalOutlined />,
    },
  ];

  const RegionTableColumns = [
    {
      title: "#",
      key: "index",
      render: (text, record, index) => {
        return <>{index + 1}</>;
      },
    },
    {
      title: t("name_en"),
      dataIndex: "name_en",
    },
    {
      title: t("name_ar"),
      dataIndex: "name_ar",
    },
    {
      title: t("country"),
      dataIndex: "country",
      render: (country) => {
        return country.name_en;
      },
    },
    {
      title: t("col_status"),
      dataIndex: "is_active",
      render: (is_active) => {
        return is_active ? (
          <Tag color="green">{t("active")}</Tag>
        ) : (
          <Tag color="red">{t("inactive")}</Tag>
        );
      },
    },
    {
      title: t("col_action"),
      width: "20%",
      render: (record) => {
        return (
          <>
            <Link to={"regions/" + record.id}>
              <Button type="primary" size="small">
                {t("view_cities")}
              </Button>
            </Link>

            <Button
              type="link"
              onClick={() => handleEditModal(record.id)}
              title={t("region_edit")}
            >
              <EditTwoTone twoToneColor="#1890ff" />
            </Button>
            <Popconfirm
              title={t("warning_delete")}
              onConfirm={() => handleDeleteRegion(record.id)}
              okText={t("yes")}
              cancelText={t("no")}
            >
              <DeleteTwoTone
                twoToneColor="#ff0000"
                title={t("delete_entity")}
              />
            </Popconfirm>
          </>
        );
      },
    },
  ];

  return (
    <>
      <CustomBreadcrumb routes={breadcrumbRoutes} />
      <PageHeader
        className="site-page-header"
        title={t("menu_regions")}
        ghost={false}
        extra={[
          <Button
            key="open-add-region-modal"
            type="primary"
            onClick={showAddModal}
          >
            + {t("region_add")}
          </Button>,
        ]}
      />
      <Card>
        <Table
          columns={RegionTableColumns}
          dataSource={regionsTableData}
          loading={isDataLoading}
          rowKey="id"
          pagination={false}
        />
      </Card>
      <Modal
        visible={modalVisible}
        title={modalTitle}
        onOk={handleAddEditRegion}
        onCancel={handleCancel}
        footer={[
          <Button key="back" loading={loading} onClick={handleCancel}>
            {t("cancel")}
          </Button>,
          <Button
            key="submit"
            type="primary"
            loading={loading}
            onClick={handleAddEditRegion}
          >
            {modalTitle === ADD_REGION || modalTitle === t("region_add")
              ? t("region_add")
              : t("update")}
          </Button>,
        ]}
      >
        <Form
          name="add-region-form"
          layout="vertical"
          onFinish={handleAddEditRegion}
          style={{ width: "100%", margin: "0" }}
          form={regionForm}
        >
          <Form.Item
            name="name_en"
            label={t("name_en")}
            rules={[
              {
                required: true,
                message: t("validate_name_required"),
                whitespace: true,
              },
              {
                min: 3,
                message: t("validate_name_min_characters"),
              },
            ]}
            hasFeedback
          >
            <Input />
          </Form.Item>

          <Form.Item
            name="name_ar"
            label={t("name_ar")}
            rules={[
              {
                required: true,
                message: t("validate_name_required"),
                whitespace: true,
              },
              {
                min: 3,
                message: t("validate_name_min_characters"),
              },
            ]}
            hasFeedback
          >
            <Input />
          </Form.Item>

          <Form.Item
            name="country_id"
            label={t("country")}
            rules={[
              {
                required: true,
                message: t("validate_country_required"),
              },
            ]}
            hasFeedback
          >
            <Select
              allowClear
              options={countries.map((country) => {
                return {
                  label: country.name,
                  value: country.id,
                };
              })}
            ></Select>
          </Form.Item>

          <Form.Item
            name="is_active"
            label={t("status")}
            rules={[
              {
                required: true,
                message: t("validate_status_required"),
              },
            ]}
          >
            <Radio.Group
              defaultValue={true}
              value={true}
              buttonStyle="solid"
              key="is-active"
            >
              <Radio.Button key="is-active-false" value={false}>
                {t("inactive")}
              </Radio.Button>
              <Radio.Button key="is-active-true" value={true}>
                {t("active")}
              </Radio.Button>
            </Radio.Group>
          </Form.Item>

          <Form.Item name="id" style={{ display: "none" }}>
            <input type="hidden" />
          </Form.Item>
        </Form>
      </Modal>
    </>
  );
};

export default Regions;
