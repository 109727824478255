import React, { useEffect, useState } from "react";
import { Form, Button, Select, Modal } from "antd";
import { useTranslation } from "react-i18next";
import { updateCompanyCSR, getStaffs } from "../../../network/network";
import NotificationWithIcon from "../../../components/NotificationWithIcon";

const UpdateCompanyAdminSaff = ({ company, reloadCallback }) => {
  const [updateModal, setUpdateModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [staffs, setStaffs] = useState([]);
  const { t } = useTranslation();
  const [UpdateCsrForm] = Form.useForm();

  useEffect(() => {
    setLoading(true);
    getStaffs()
      .then((response) => {
        setStaffs(response.data.data.staff);
      })
      .finally(() => {
        setLoading(false);
      });
  }, []);

  const showEditModel = () => {
    // Set Form Fields
    UpdateCsrForm.setFieldsValue({
      company_id: company.id,
      staff_id: company.admin_staff?.id,
    });
    setUpdateModal(true);
  };

  const handleUpdateCSR = () => {
    UpdateCsrForm.validateFields()
      .then((values) => {
        // Set Loading True
        setLoading(true);
        // Sending Request to API
        updateCompanyCSR(values)
          .then((response) => {
            if (response.data.success === true) {
              // Success
              NotificationWithIcon(
                "success",
                t("staff_updated"),
                response.data.message
              );
              reloadCallback();
            } else {
              // Error
              NotificationWithIcon(
                "error",
                t("something_went_wrong"),
                response.data.message
              );
            }
            // Hide Modal
            setUpdateModal(false);
          })
          .catch((info) => {
            console.log("API Failed:", info.response);
            // Error
            NotificationWithIcon(
              "error",
              t("something_went_wrong"),
              info.response.data.data.errors.name
            );
          })
          .finally(() => {
            // Set Loading False
            setLoading(false);
          });
      })
      .catch((info) => {
        console.log("Validate Failed:", info);
      });
  };

  return (
    <>
      <Button
        type="primary"
        onClick={showEditModel}
        style={{ marginRight: "20px" }}
      >
        {t("update_csr")}
      </Button>

      {/* update company CSR */}
      <Modal
        title={t("update_csr")}
        visible={updateModal}
        onCancel={() => {
          setUpdateModal(false);
        }}
        onOk={handleUpdateCSR}
        footer={[
          <Button
            key="back"
            loading={loading}
            onClick={() => setUpdateModal(false)}
          >
            {t("cancel")}
          </Button>,
          <Button
            key="submit"
            type="primary"
            loading={loading}
            onClick={handleUpdateCSR}
          >
            {t("update")}
          </Button>,
        ]}
      >
        <Form
          name="update-CSR-form"
          layout="vertical"
          onFinish={handleUpdateCSR}
          style={{ width: "100%" }}
          form={UpdateCsrForm}
        >
          <Form.Item
            name="staff_id"
            label={t("select_company_staff")}
            rules={[
              {
                required: true,
                message: t("validate_company_staff_required"),
              },
            ]}
            hasFeedback
          >
            <Select
              showSearch
              allowClear
              options={staffs.map((staff) => {
                return {
                  label: staff.name,
                  value: staff.id,
                };
              })}
              filterOption={(input, option) =>
                (option?.label ?? "")
                  .toLowerCase()
                  .includes(input.toLowerCase())
              }
            />
          </Form.Item>
          <Form.Item name="company_id" style={{ display: "none" }}>
            <input type="hidden" />
          </Form.Item>
        </Form>
      </Modal>
    </>
  );
};

export default UpdateCompanyAdminSaff;
