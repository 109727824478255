import React, { useEffect, useState, useCallback } from "react";
import { Pagination } from "antd";
import { getInprogressTickets } from "../../../network/network";
import TicketsTable from "./TicketsTable";
import { useHistory, useLocation } from "react-router-dom";

const InprogressTickets = ({ reloadCallback }) => {
  const history = useHistory();
  const location = useLocation();
  const query = new URLSearchParams(location.search);
  const urlStatus = query.get("status");
  const urlPage = parseInt(query.get("page")) || 1;

  const [tickets, setTickets] = useState([]);
  const [loading, setLoading] = useState(false);
  const [dataLoading, setDataLoading] = useState(false);
  const [paginationData, setPaginationData] = useState({});
  const [paginationPage, setPaginationPage] = useState(urlPage);

  const handlePagination = useCallback(
    (page) => {
      setPaginationPage(page);
      const params = new URLSearchParams();
      if (urlStatus) {
        params.append("status", urlStatus);
      }
      params.append("page", page);

      history.push({
        pathname: "/helpdesk",
        search: params.toString(),
      });
    },
    [urlStatus, history]
  );

  useEffect(() => {
    setDataLoading(true);
    getInprogressTickets(paginationPage)
      .then((response) => {
        setTickets(response.data.data.tickets);
        setPaginationData(response.data.data.pagination);
      })
      .catch((error) => {
        console.error("Error fetching inprogress tickets:", error);
      })
      .finally(() => {
        setDataLoading(false);
      });
    // Reload Callback
    reloadCallback();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loading, paginationPage]);

  useEffect(() => {
    setPaginationPage(urlPage);
  }, [urlPage]);

  return (
    <>
      <TicketsTable
        tickets={tickets}
        reloadCallback={() => setLoading(!loading)}
        currentTab="inprogress_tickets"
        loading={dataLoading}
        paginationData={paginationData}
      />
      {paginationData.current_page &&
        paginationData.count > 0 &&
        paginationData.total > paginationData.per_page && (
          <Pagination
            current={paginationPage}
            total={paginationData.total}
            pageSize={paginationData.per_page}
            onChange={handlePagination}
            showSizeChanger={false}
            style={{
              marginTop: "20px",
              textAlign: "center",
            }}
          />
        )}
    </>
  );
};

export default InprogressTickets;
