import React, { useEffect, useState } from "react";
import { PageHeader, Card, Form, Input, Button, Table, Modal } from "antd";
import {
  HomeOutlined,
  EditTwoTone,
  IssuesCloseOutlined,
} from "@ant-design/icons";
import {
  getIssueTypes,
  createIssueType,
  updateIssueType,
} from "../../../network/network";
import CustomBreadcrumb from "../../../components/CustomBreadcrumb";
import NotificationWithIcon from "../../../components/NotificationWithIcon";
import { useTranslation } from "react-i18next";
import "react-phone-input-2/lib/style.css";

const IssueTypes = () => {
  const ADD_ISSUE_TYPE = "Add Issue Type";
  const [modalTitle, setModalTitle] = useState(ADD_ISSUE_TYPE);
  const [modalVisible, setModalVisible] = useState(false);
  const [loading, setLoading] = useState(false);
  const [isDataLoading, setIsDataLoading] = useState(false);
  const [reloadTableData, setReloadTableData] = useState(false);
  const [issueTypesTableData, setIssueTypesTableData] = useState([]);
  const [issueTypeForm] = Form.useForm();
  const { t } = useTranslation();

  useEffect(() => {
    setIsDataLoading(true);
    getIssueTypes()
      .then((response) => {
        setIssueTypesTableData(response.data.data.issue_types);
      })
      .finally(() => {
        setIsDataLoading(false);
      });
  }, [reloadTableData]);

  const showAddModal = () => {
    // Set Modal Title
    setModalTitle(t("issue_type_add"));

    // Reset Form Fields
    issueTypeForm.resetFields();

    // Show Modal
    setModalVisible(true);
  };

  const handleAPIResponse = (data, type) => {
    if (data.success === true) {
      setReloadTableData(!reloadTableData);
      // Success
      if (type === ADD_ISSUE_TYPE) {
        NotificationWithIcon(
          "success",
          t("issue_type_added"),
          t("issue_type_added_successfully")
        );
      } else {
        NotificationWithIcon(
          "success",
          t("issue_type_updated"),
          t("issue_type_updated_successfully")
        );
      }
    } else {
      // Error
      NotificationWithIcon("error", t("something_went_wrong"), data.message);
    }
    // Reset Form Fields
    issueTypeForm.resetFields();
    // Hide Modal
    setModalVisible(false);
  };

  const handleAddEditIssueType = () => {
    issueTypeForm
      .validateFields()

      .then((values) => {
        // Set Loading True
        setLoading(true);

        // Sending Request to API
        if (
          modalTitle === ADD_ISSUE_TYPE ||
          modalTitle === t("issue_type_add")
        ) {
          createIssueType(values)
            .then((response) => {
              let data = response.data || response.response.data;
              if (data.success === true) {
                return handleAPIResponse(data, ADD_ISSUE_TYPE);
              } else {
                let errors = data?.data?.errors;
                if (errors && Object.keys(errors).length) {
                  // Error
                  NotificationWithIcon(
                    "error",
                    t("something_went_wrong"),
                    errors[Object.keys(errors)[0]]
                  );
                }
              }
            })
            .finally(() => {
              // Set Loading False
              setLoading(false);
            });
        } else {
          updateIssueType(values)
            .then((response) => {
              let data = response.data || response.response.data;
              if (data.success === true) {
                return handleAPIResponse(data, null);
              } else {
                let errors = data?.data?.errors;
                if (errors && Object.keys(errors).length) {
                  // Error
                  NotificationWithIcon(
                    "error",
                    t("something_went_wrong"),
                    errors[Object.keys(errors)[0]]
                  );
                }
              }
            })
            .finally(() => {
              // Set Loading False
              setLoading(false);
            });
        }
      })
      .catch((info) => {
        console.log("Validate Failed:", info);
      });
  };

  const handleEditModal = (issue_type_id) => {
    // Find issue_type from DataArray
    const issue_type = issueTypesTableData.find(
      (issue) => issue.id === issue_type_id
    );

    // Set Modal Title
    setModalTitle(t("issue_type_edit") + " : " + issue_type.type);

    // Show Modal
    setModalVisible(true);

    // Set Form Fields
    issueTypeForm.setFieldsValue({
      id: issue_type.id,
      type: issue_type.type,
    });
  };

  const handleCancel = () => {
    // Reset Form Fields
    issueTypeForm.resetFields();
    setModalVisible(false);
  };

  const breadcrumbRoutes = [
    {
      path: "home",
      breadcrumbName: t("menu_home"),
      icon: <HomeOutlined />,
    },
    {
      path: "issue_types",
      breadcrumbName: t("menu_issue_types"),
      icon: <IssuesCloseOutlined />,
    },
  ];

  const IssueTypesTableColumns = [
    {
      title: "#",
      key: "index",
      width: "100px",
      render: (text, record, index) => {
        return <>{index + 1}</>;
      },
    },
    {
      title: t("type"),
      dataIndex: "type",
    },
    {
      title: t("col_action"),
      align: "center",
      width: "100px",
      render: (record) => {
        return (
          <>
            <Button
              type="link"
              onClick={() => handleEditModal(record.id)}
              title={t("issue_type_edit")}
            >
              <EditTwoTone twoToneColor="#1890ff" />
            </Button>
          </>
        );
      },
    },
  ];

  return (
    <>
      <CustomBreadcrumb routes={breadcrumbRoutes} />
      <PageHeader
        className="site-page-header"
        title={t("menu_issue_types")}
        ghost={false}
        extra={[
          <Button
            key="open-add-issue-type-modal"
            type="primary"
            onClick={showAddModal}
          >
            + {t("issue_type_add")}
          </Button>,
        ]}
      />
      <Card>
        <Table
          columns={IssueTypesTableColumns}
          dataSource={issueTypesTableData}
          loading={isDataLoading}
          rowKey="id"
          pagination={false}
        />
      </Card>
      <Modal
        visible={modalVisible}
        title={modalTitle}
        onOk={handleAddEditIssueType}
        onCancel={handleCancel}
        footer={[
          <Button key="back" loading={loading} onClick={handleCancel}>
            {t("cancel")}
          </Button>,
          <Button
            key="submit"
            type="primary"
            loading={loading}
            onClick={handleAddEditIssueType}
          >
            {modalTitle === ADD_ISSUE_TYPE || modalTitle === t("issue_type_add")
              ? t("issue_type_add")
              : t("update")}
          </Button>,
        ]}
      >
        <Form
          name="add-issue-type-form"
          layout="vertical"
          onFinish={handleAddEditIssueType}
          style={{ width: "100%", margin: "0" }}
          form={issueTypeForm}
        >
          <Form.Item
            name="type"
            label={t("type")}
            rules={[
              {
                required: true,
                message: t("validate_issue_types_required"),
              },
              {
                min: 3,
                message: t("validate_issue_types_min_characters"),
              },
            ]}
            hasFeedback
          >
            <Input />
          </Form.Item>
          <Form.Item name="id" style={{ display: "none" }}>
            <input type="hidden" />
          </Form.Item>
        </Form>
      </Modal>
    </>
  );
};

export default IssueTypes;
