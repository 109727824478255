import React, { useEffect, useState } from "react";
import { Empty, Pagination, Spin, Timeline } from "antd";

import {
  // AlignLeftOutlined,
  // BellOutlined,
  // CalendarOutlined,
  // CheckCircleTwoTone,
  UserOutlined,
} from "@ant-design/icons";
import { getLogs } from "../../../network/network";
import { useParams } from "react-router-dom";
import moment from "moment";

const CompanyLogs = () => {
  const [loading, setLoading] = useState(false);
  const [logsData, setLogsData] = useState([]);
  const [paginationData, setPaginationData] = useState([]);
  const [paginationPage, setPaginationPage] = useState(1);
  let { companyId } = useParams();

  useEffect(() => {
    setLoading(true);
    getLogs(companyId, paginationPage)
      .then((response) => {
        setLogsData(response.data.data.logs);
        setPaginationData(response.data.data.pagination);
      })
      .finally(() => {
        setLoading(false);
      });
  }, [companyId, paginationPage]);

  const handlePagination = (page, pageSize) => {
    setPaginationPage(page);
  };

  return (
    <>
      <Spin size="large" spinning={loading}>
        <div style={{ marginTop: "20px" }}>
          <Timeline className="log_timeLine">
            {logsData.length > 0 ? (
              logsData.map((log) => (
                <Timeline.Item key={log.id}>
                  <div
                    className="log_card"
                    style={{
                      boxShadow: "rgba(154, 154, 154, 0.537) 0 0 20px -5px",
                      margin: "0 10px 0 0",
                      borderRadius: "10px",
                    }}
                  >
                    <div
                      className="log_head"
                      style={{
                        padding: "20px",
                      }}
                    >
                      <div style={{ display: "flex", alignItems: "center" }}>
                        <div
                          style={{
                            display: "flex",
                            flexGrow: "1",
                            alignItems: "center",
                          }}
                        >
                          <div style={{ marginRight: "10px" }}>
                            {log.created_by && (
                              <UserOutlined style={{ fontSize: "18px" }} />
                            )}
                          </div>
                          <div
                            style={{
                              display: "flex",
                              flexGrow: "1",
                            }}
                          >
                            {log.created_by && (
                              <span
                                style={{
                                  fontWeight: "bold",
                                  marginRight: "5px",
                                }}
                              >
                                {log.created_by.name}
                              </span>
                            )}
                            {log.action} -
                            <span
                              style={{
                                fontWeight: "bold",
                                marginLeft: "5px",
                              }}
                            >
                              {moment(log.created_at).format(
                                "MMM. D, YYYY HH:mm"
                              )}
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </Timeline.Item>
              ))
            ) : (
              <Empty />
            )}
            {/* <Timeline.Item>
            <div
              className="log_card"
              style={{
                boxShadow: "rgba(154, 154, 154, 0.537) 0 0 20px -5px",
                margin: "0 10px 0 0",
                borderRadius: "10px",
              }}
            >
              <div
                className="log_head"
                style={{
                  padding: "20px",
                }}
              >
                <div style={{ display: "flex", alignItems: "center" }}>
                  <div
                    style={{
                      display: "flex",
                      flexGrow: "1",
                      alignItems: "center",
                    }}
                  >
                    <div style={{ marginRight: "10px" }}>
                      <UserOutlined style={{ fontSize: "18px" }} />
                    </div>
                    <div
                      style={{
                        display: "flex",
                        flexGrow: "1",
                      }}
                    >
                      <span
                        style={{
                          fontWeight: "bold",
                          marginRight: "5px",
                        }}
                      >
                        Beaulah Metz
                      </span>
                      activate a company -
                      <span
                        style={{
                          fontWeight: "bold",
                          marginLeft: "5px",
                        }}
                      >
                        April 14, 2023 17:45
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Timeline.Item>
          <Timeline.Item>
            <div
              className="log_card"
              style={{
                boxShadow: "rgba(154, 154, 154, 0.537) 0 0 20px -5px",
                margin: "0 10px 0 0",
                borderRadius: "10px",
              }}
            >
              <div
                className="log_head"
                style={{
                  padding: "20px",
                }}
              >
                <div style={{ display: "flex", alignItems: "center" }}>
                  <div
                    style={{
                      display: "flex",
                      flexGrow: "1",
                      alignItems: "center",
                    }}
                  >
                    <div style={{ marginRight: "10px" }}>
                      <UserOutlined style={{ fontSize: "18px" }} />
                    </div>
                    <div
                      style={{
                        display: "flex",
                        flexGrow: "1",
                      }}
                    >
                      <span
                        style={{
                          fontWeight: "bold",
                          marginRight: "5px",
                        }}
                      >
                        Asim saif
                      </span>
                      add a new device -
                      <span
                        style={{
                          fontWeight: "bold",
                          marginLeft: "5px",
                        }}
                      >
                        April 22, 2023 03:45
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Timeline.Item>
          <Timeline.Item>
            <div
              className="log_card"
              style={{
                boxShadow: "rgba(154, 154, 154, 0.537) 0 0 20px -5px",
                margin: "0 10px 0 0",
                borderRadius: "10px",
              }}
            >
              <div
                className="log_head"
                style={{
                  padding: "20px",
                  borderBottom: "1px solid #e2e8f0",
                }}
              >
                <div style={{ display: "flex", alignItems: "center" }}>
                  <div
                    style={{
                      display: "flex",
                      flexGrow: "1",
                      alignItems: "center",
                    }}
                  >
                    <div style={{ marginRight: "10px" }}>
                      <UserOutlined style={{ fontSize: "18px" }} />
                    </div>
                    <div
                      style={{
                        display: "flex",
                        flexGrow: "1",
                      }}
                    >
                      <span
                        style={{
                          fontWeight: "bold",
                          marginRight: "5px",
                        }}
                      >
                        Sajid Ali
                      </span>
                      left a note on -
                      <span
                        style={{
                          fontWeight: "bold",
                          marginLeft: "5px",
                        }}
                      >
                        April 14, 2023 17:45
                      </span>
                    </div>
                  </div>
                </div>
              </div>
              <div className="notes_body" style={{ padding: "20px" }}>
                <div style={{ display: "flex", marginBottom: "10px" }}>
                  <div
                    style={{
                      textAlign: "justify",
                    }}
                  >
                    Dolorum 1 aut earum voluptatibus provident molestias
                    incidunt praesentium. Maxime dolorem iste dolorem omnis
                    consequatur blanditiis. Ea sunt sunt quo illo. Facere nihil
                    ut voluptatibus blanditiis ut.Dolorum aut earum voluptatibus
                    provident molestias incidunt praesentium. Maxime dolorem
                    iste dolorem omnis consequatur blanditiis. Ea sunt sunt quo
                    illo. Facere nihil ut voluptatibus blanditiis ut.
                  </div>
                </div>
              </div>
            </div>
          </Timeline.Item>
          <Timeline.Item>
            <div
              className="activity_card"
              style={{
                boxShadow: "rgba(154, 154, 154, 0.537) 0 0 20px -5px",
                margin: "0px 10px 0px 0px",
                borderRadius: "10px",
              }}
            >
              <div
                className="log_head"
                style={{
                  padding: "20px",
                  borderBottom: "1px solid #e2e8f0",
                }}
              >
                <div style={{ display: "flex", alignItems: "center" }}>
                  <div
                    style={{
                      display: "flex",
                      flexGrow: "1",
                      alignItems: "center",
                    }}
                  >
                    <div style={{ marginRight: "10px" }}>
                      <UserOutlined style={{ fontSize: "18px" }} />
                    </div>
                    <div
                      style={{
                        display: "flex",
                        flexGrow: "1",
                      }}
                    >
                      <span
                        style={{
                          fontWeight: "bold",
                          marginRight: "5px",
                        }}
                      >
                        Sajid Ali
                      </span>
                      add a new activity -
                      <span
                        style={{
                          fontWeight: "bold",
                          marginLeft: "5px",
                        }}
                      >
                        April 14, 2023 17:45
                      </span>
                    </div>
                  </div>
                </div>
              </div>
              <div
                className="activity_head"
                style={{
                  padding: "20px",
                  borderBottom: "1px solid #e2e8f0",
                }}
              >
                <div style={{ display: "flex", alignItems: "center" }}>
                  <div style={{ marginRight: "10px" }}>
                    <CheckCircleTwoTone
                      twoToneColor="#52c41a"
                      style={{ fontSize: "18px" }}
                    />
                  </div>
                  <div
                    style={{
                      display: "flex",
                      flexGrow: "1",
                      alignItems: "center",
                    }}
                  >
                    <div
                      className="activity_title"
                      style={{
                        display: "flex",
                        flexGrow: "1",
                      }}
                    >
                      <h3 style={{ marginBottom: "0" }}>
                        Activity 1 Quis deserunt dicta cupiditate est
                        consequatur.
                      </h3>
                    </div>
                    <div
                      className="activity_type"
                      style={{
                        backgroundColor: "rgb(226, 232, 240)",
                        padding: "2px 15px",
                        borderRadius: "35rem",
                      }}
                    >
                      Call
                    </div>
                  </div>
                </div>
              </div>
              <div className="activity_body" style={{ padding: "20px" }}>
                <div style={{ display: "flex", marginBottom: "10px" }}>
                  <div style={{ marginRight: "10px", marginTop: "2px" }}>
                    <span>
                      <AlignLeftOutlined style={{ fontSize: "18px" }} />
                    </span>
                  </div>
                  <div
                    style={{
                      textAlign: "justify",
                    }}
                  >
                    Dolorum 1 aut earum voluptatibus provident molestias
                    incidunt praesentium. Maxime dolorem iste dolorem omnis
                    consequatur blanditiis. Ea sunt sunt quo illo. Facere nihil
                    ut voluptatibus blanditiis ut.Dolorum aut earum voluptatibus
                    provident molestias incidunt praesentium. Maxime dolorem
                    iste dolorem omnis consequatur blanditiis. Ea sunt sunt quo
                    illo. Facere nihil ut voluptatibus blanditiis ut.
                  </div>
                </div>
                <div style={{ display: "flex", marginBottom: "10px" }}>
                  <div style={{ marginRight: "10px", marginTop: "2px" }}>
                    <span>
                      <UserOutlined style={{ fontSize: "18px" }} />
                    </span>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      flexGrow: "1",
                    }}
                  >
                    <div
                      style={{
                        display: "flex",
                        flexGrow: "1",
                      }}
                    >
                      <div>Zain</div>
                    </div>
                  </div>
                </div>
                <div style={{ display: "flex", marginBottom: "10px" }}>
                  <div style={{ marginRight: "10px", marginTop: "2px" }}>
                    <span>
                      <CalendarOutlined style={{ fontSize: "18px" }} />
                    </span>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      flexGrow: "1",
                    }}
                  >
                    <div>April 13, 2023 17:15 - April 14, 2023 17:45</div>
                  </div>
                </div>
                <div style={{ display: "flex" }}>
                  <div style={{ marginRight: "10px", marginTop: "2px" }}>
                    <span>
                      <BellOutlined style={{ fontSize: "18px" }} />
                    </span>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      flexGrow: "1",
                    }}
                  >
                    <div>Reminder set for 30 minutes before</div>
                  </div>
                </div>
              </div>
            </div>
          </Timeline.Item> */}
          </Timeline>
          {paginationData.current_page &&
            paginationData.count > 0 &&
            paginationData.total > paginationData.per_page && (
              <Pagination
                current={paginationData.current_page}
                total={paginationData.total}
                pageSize={paginationData.per_page}
                onChange={handlePagination}
                showSizeChanger={false}
                defaultCurrent={paginationData.current_page}
                style={{
                  marginTop: "20px",
                  textAlign: "center",
                }}
              />
            )}
        </div>
      </Spin>
    </>
  );
};

export default CompanyLogs;
