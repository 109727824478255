import { useState } from "react";
// import {
//   createEmployee,
//   activateEmployee,
//   deactivateEmployee,
// } from "../network/network";
// import NotificationWithIcon from "./NotificationWithIcon";
import { Form, Button, Table, Modal, Tag, Input } from "antd";
// import { LockOutlined, UnlockOutlined, EditTwoTone } from "@ant-design/icons";
import { useTranslation } from "react-i18next";
import { USER_TYPE_BUSINESS_OWNER } from "../../../configs/constants";

const EmployeesTableWithEditModal = ({ employees }) => {
  const [loading, setLoading] = useState(false);
  // const [reload, setReload] = useState(false);
  // const [reloadTableData, setReloadTableData] = useState(false);
  const [modalVisible, setModalVisible] = useState(false);
  const [editEmployeeForm] = Form.useForm();
  const { t } = useTranslation();

  const EmployeesTableColumns = [
    {
      title: "#",
      key: "index",
      render: (text, record, index) => {
        return <p>{index + 1}</p>;
      },
    },
    {
      title: t("name"),
      dataIndex: "name",
      width: "25%",
    },
    // {
    //   title: t("email"),
    //   dataIndex: "email",
    // },
    {
      title: t("phone_number"),
      dataIndex: "phone",
    },
    {
      title: t("branch"),
      dataIndex: "branch_name",
    },
    {
      title: t("type"),
      dataIndex: "type",
      render: (type) => {
        return type === USER_TYPE_BUSINESS_OWNER ? (
          <Tag color="green">{t("owner")}</Tag>
        ) : (
          <Tag color="blue">{t("employee")}</Tag>
        );
      },
    },
    {
      title: t("col_status"),
      dataIndex: "is_active",
      render: (is_active) => {
        return is_active ? (
          <Tag color="green">{t("active")}</Tag>
        ) : (
          <Tag color="red">{t("inactive")}</Tag>
        );
      },
    },
    // {
    //   title: t("col_action"),
    //   render: (employee) => {
    //     return (
    //       <>
    //         <Button
    //           type="link"
    //           onClick={() => handleEditModal(employee.id)}
    //           title="Edit Employee"
    //         >
    //           <EditTwoTone twoToneColor="#1890ff" />
    //         </Button>
    //         {employee.is_active ? (
    //           <Button
    //             type="link"
    //             onClick={() => handleDeactivate(employee.id)}
    //             style={{ padding: 2, fontSize: "20px", color: "#ff0000" }}
    //           >
    //             <LockOutlined title={t("employee_deactivate")} />
    //           </Button>
    //         ) : (
    //           <Button
    //             type="link"
    //             onClick={() => handleActivate(employee.id)}
    //             style={{ padding: 2, fontSize: "20px", color: "#087c08" }}
    //           >
    //             <UnlockOutlined title={t("employee_activate")} />
    //           </Button>
    //         )}
    //       </>
    //     );
    //   },
    // },
  ];

  const handleAdd = () => {
    editEmployeeForm
      .validateFields()
      .then((values) => {
        // Set Loading True
        setLoading(true);
        // Sending Request to API
        // createBranch(values)
        //   .then((response) => {
        //     if (response.data.success === true) {
        //       setReloadTableData(!reloadTableData);
        //       // Success
        //       NotificationWithIcon(
        //         "success",
        //         t("branch_added"),
        //         t("branch_added_successfully")
        //       );
        //     } else {
        //       // Error
        //       NotificationWithIcon(
        //         "error",
        //         t("something_went_wrong"),
        //         response.data.message
        //       );
        //     }
        //     // Reset Form Fields
        //     editEmployeeForm.resetFields();
        //     // Hide Modal
        //     setModalVisible(false);
        //   })
        //   .catch((info) => {
        //     console.log("API Failed:", info.response);
        //     // Error
        //     NotificationWithIcon(
        //       "error",
        //       t("something_went_wrong"),
        //       info.response.data.data.errors.name
        //     );
        //   })
        //   .finally(() => {
        //     // Set Loading False
        //     setLoading(false);
        //   });
      })
      .catch((info) => {
        console.log("Validate Failed:", info);
      });
  };

  const handleCancel = () => {
    // Reset Form Fields
    editEmployeeForm.resetFields();
    setModalVisible(false);
  };

  // const handleEditModal = () => {};
  // const handleDeactivate = () => {};
  // const handleActivate = () => {};

  return (
    <>
      <Table
        columns={EmployeesTableColumns}
        dataSource={employees}
        rowKey="id"
        pagination={false}
      />
      <Modal
        visible={modalVisible}
        title={t("branch_edit")}
        onOk={handleAdd}
        onCancel={handleCancel}
        footer={[
          <Button key="back" loading={loading} onClick={handleCancel}>
            {t("cancel")}
          </Button>,
          <Button
            key="submit"
            type="primary"
            loading={loading}
            onClick={handleAdd}
          >
            {t("branch_edit")}
          </Button>,
        ]}
      >
        <Form
          name="edit-branch-form"
          layout="vertical"
          onFinish={handleAdd}
          style={{ width: "100%", margin: "0" }}
          form={editEmployeeForm}
        >
          <Form.Item
            name="name"
            label={t("name")}
            rules={[
              {
                required: true,
                message: t("validate_name_required"),
                whitespace: true,
              },
            ]}
            hasFeedback
          >
            <Input />
          </Form.Item>

          <Form.Item
            name="address"
            label={t("address")}
            rules={[
              {
                required: true,
                message: t("validate_address_required"),
                whitespace: true,
              },
            ]}
            hasFeedback
          >
            <Input />
          </Form.Item>

          <Form.Item name="code" label={t("code")} hasFeedback>
            <Input />
          </Form.Item>
        </Form>
      </Modal>
    </>
  );
};

export default EmployeesTableWithEditModal;
