import React from "react";
import ReactDOM from "react-dom";
import App from "./pages/App/App";
import AppContextProvider from "./context/AppContextProvider";
import { BrowserRouter as Router } from "react-router-dom";
import "./i18n";

import Echo from 'laravel-echo';
import Pusher from 'pusher-js';

window.Pusher = Pusher;
window.Echo = new Echo({
    broadcaster: 'pusher',
    key: process.env.REACT_APP_PUSHER_APP_KEY,
    cluster: process.env.REACT_APP_PUSHER_APP_CLUSTER ?? 'mt1',
    wsHost: process.env.REACT_APP_PUSHER_HOST ? process.env.REACT_APP_PUSHER_HOST : `ws-${process.env.REACT_APP_PUSHER_APP_CLUSTER}.pusher.com`,
    wsPort: process.env.REACT_APP_PUSHER_PORT ?? 80,
    wssPort: process.env.REACT_APP_PUSHER_PORT ?? 443,
    forceTLS: (process.env.REACT_APP_PUSHER_SCHEME ?? 'https') === 'https',
    enabledTransports: ['ws', 'wss'],
    disableStats: true,
    authEndpoint: process.env.REACT_APP_API_ROOT + "/api/broadcasting/auth",
});

if (localStorage.getItem("access_token")) {
    window.Echo.connector.options.auth.headers['Authorization'] = 'Bearer ' + localStorage.getItem("access_token");
    window.Echo.connector.options.auth.headers['Accept'] = "application/json";
}

ReactDOM.render(
  <React.StrictMode>
    <Router>
      <AppContextProvider>
        <App />
      </AppContextProvider>
    </Router>
  </React.StrictMode>,
  document.getElementById("root")
);
