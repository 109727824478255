import "./Managers.css";
import React, { useEffect, useState } from "react";
import {
  PageHeader,
  Card,
  Form,
  Input,
  Button,
  Table,
  Modal,
  Popconfirm,
  Radio,
  Tag,
  Row,
  Col,
} from "antd";
import {
  HomeOutlined,
  EditTwoTone,
  DeleteTwoTone,
  DeploymentUnitOutlined,
} from "@ant-design/icons";
import {
  getManagers,
  createManager,
  updateManager,
  deleteManager,
} from "../../../network/network";
import CustomBreadcrumb from "../../../components/CustomBreadcrumb";
import NotificationWithIcon from "../../../components/NotificationWithIcon";
import { useTranslation } from "react-i18next";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";

const Managers = () => {
  const ADD_MANAGER = "Add Manager";
  const [modalTitle, setModalTitle] = useState(ADD_MANAGER);
  const [modalVisible, setModalVisible] = useState(false);
  const [loading, setLoading] = useState(false);
  const [isDataLoading, setIsDataLoading] = useState(false);
  const [reloadTableData, setReloadTableData] = useState(false);
  const [validatePassword, setValidatePassword] = useState(true);
  const [managersTableData, setManagersTableData] = useState([]);
  const [managerForm] = Form.useForm();
  const { t } = useTranslation();

  useEffect(() => {
    setIsDataLoading(true);
    getManagers()
      .then((response) => {
        setManagersTableData(response.data.data.admins);
      })
      .finally(() => {
        setIsDataLoading(false);
      });
  }, [reloadTableData]);

  const showAddModal = () => {
    // Set Modal Title
    setModalTitle(t("manager_add"));

    // Reset Form Fields
    managerForm.resetFields();
    // set managerForm Form Field true
    managerForm.setFieldsValue({
      is_active: true,
    });
    // Show Modal
    setModalVisible(true);

    // Enable Password Validation
    setValidatePassword(true);
  };

  const handleAPIResponse = (data, type) => {
    if (data.success === true) {
      setReloadTableData(!reloadTableData);
      // Success
      if (type === ADD_MANAGER) {
        NotificationWithIcon(
          "success",
          t("manager_added"),
          t("manager_added_successfully")
        );
      } else {
        NotificationWithIcon(
          "success",
          t("manager_updated"),
          t("manager_updated_successfully")
        );
      }
    } else {
      // Error
      NotificationWithIcon("error", t("something_went_wrong"), data.message);
    }
    // Reset Form Fields
    managerForm.resetFields();
    // Hide Modal
    setModalVisible(false);
  };

  const handleAddEditManager = () => {
    managerForm
      .validateFields()

      .then((values) => {
        console.log(values);
        // Set Loading True
        setLoading(true);

        // Sending Request to API
        if (modalTitle === ADD_MANAGER || modalTitle === t("manager_add")) {
          createManager(values)
            .then((response) => {
              let data = response.data || response.response.data;
              if (data.success === true) {
                return handleAPIResponse(data, ADD_MANAGER);
              } else {
                let errors = data?.data?.errors;
                if (errors && Object.keys(errors).length) {
                  // Error
                  NotificationWithIcon(
                    "error",
                    t("something_went_wrong"),
                    errors[Object.keys(errors)[0]]
                  );
                }
              }
            })
            .finally(() => {
              // Set Loading False
              setLoading(false);
            });
        } else {
          updateManager(values)
            .then((response) => {
              let data = response.data || response.response.data;
              if (data.success === true) {
                return handleAPIResponse(data, null);
              } else {
                let errors = data?.data?.errors;
                if (errors && Object.keys(errors).length) {
                  // Error
                  NotificationWithIcon(
                    "error",
                    t("something_went_wrong"),
                    errors[Object.keys(errors)[0]]
                  );
                }
              }
            })
            .finally(() => {
              // Set Loading False
              setLoading(false);
            });
        }
      })
      .catch((info) => {
        console.log("Validate Failed:", info);
      });
  };

  const handleEditModal = (manager_id) => {
    // Find manager from DataArray
    const manager = managersTableData.find((emp) => emp.id === manager_id);

    // Set Modal Title
    setModalTitle(t("manager_edit") + " : " + manager.name);

    // Show Modal
    setModalVisible(true);

    // Set Form Fields
    managerForm.setFieldsValue({
      id: manager.id,
      first_name: manager.first_name,
      last_name: manager.last_name,
      phone: manager.phone,
      email: manager.email,
      is_active: manager.is_active === 1 ? true : false,
    });
    // Disable Password Validation
    setValidatePassword(false);
  };

  const handleCancel = () => {
    // Reset Form Fields
    managerForm.resetFields();
    setModalVisible(false);
  };

  const handledeleteManager = (id) => {
    deleteManager(id)
      .then((response) => {
        if (response.data.success === true) {
          setReloadTableData(!reloadTableData);
          // Success
          NotificationWithIcon(
            "success",
            t("manager_deleted"),
            t("manager_deleted_successfully")
          );
        } else {
          // Error
          NotificationWithIcon(
            "error",
            t("something_went_wrong"),
            response.data.message
          );
        }
      })
      .catch((info) => {
        // Error
        console.log("Delete error: ", info.response.data.message);
        NotificationWithIcon(
          "error",
          t("something_went_wrong"),
          info.response.data.message
        );
      });
  };

  const passwordValueChanged = (e) => {
    if (
      e.target.value.length === 0 &&
      !(modalTitle === ADD_MANAGER || modalTitle === t("manager_add"))
    ) {
      setValidatePassword(false);
    } else {
      setValidatePassword(true);
    }
  };

  const breadcrumbRoutes = [
    {
      path: "home",
      breadcrumbName: t("menu_home"),
      icon: <HomeOutlined />,
    },
    {
      path: "managers",
      breadcrumbName: t("menu_managers"),
      icon: <DeploymentUnitOutlined />,
    },
  ];

  const ManagersTableColumns = [
    {
      title: "#",
      key: "index",
      render: (text, record, index) => {
        return <>{index + 1}</>;
      },
    },
    {
      title: t("name"),
      dataIndex: "name",
    },
    {
      title: t("email"),
      dataIndex: "email",
    },
    {
      title: t("phone_number"),
      dataIndex: "phone",
    },

    {
      title: t("col_status"),
      dataIndex: "is_active",
      render: (is_active) => {
        return is_active ? (
          <Tag color="green">{t("active")}</Tag>
        ) : (
          <Tag color="red">{t("inactive")}</Tag>
        );
      },
    },

    {
      title: t("col_action"),
      render: (record) => {
        return (
          <>
            <Button
              type="link"
              onClick={() => handleEditModal(record.id)}
              title={t("manager_edit")}
            >
              <EditTwoTone twoToneColor="#1890ff" />
            </Button>
            <Popconfirm
              title={t("warning_delete")}
              onConfirm={() => handledeleteManager(record.id)}
              okText={t("yes")}
              cancelText={t("no")}
            >
              <DeleteTwoTone
                twoToneColor="#ff0000"
                title={t("delete_entity")}
              />
            </Popconfirm>
          </>
        );
      },
    },
  ];

  return (
    <>
      <CustomBreadcrumb routes={breadcrumbRoutes} />
      <PageHeader
        className="site-page-header"
        title={t("menu_managers")}
        ghost={false}
        extra={[
          <Button
            key="open-add-manager-modal"
            type="primary"
            onClick={showAddModal}
          >
            + {t("manager_add")}
          </Button>,
        ]}
      />
      <Card>
        <Table
          columns={ManagersTableColumns}
          dataSource={managersTableData}
          loading={isDataLoading}
          rowKey="id"
          pagination={false}
        />
      </Card>
      <Modal
        visible={modalVisible}
        title={modalTitle}
        onOk={handleAddEditManager}
        onCancel={handleCancel}
        width={1000}
        footer={[
          <Button key="back" loading={loading} onClick={handleCancel}>
            {t("cancel")}
          </Button>,
          <Button
            key="submit"
            type="primary"
            loading={loading}
            onClick={handleAddEditManager}
          >
            {modalTitle === ADD_MANAGER || modalTitle === t("manager_add")
              ? t("manager_add")
              : t("update")}
          </Button>,
        ]}
      >
        <Form
          name="add-managers-form"
          layout="vertical"
          onFinish={handleAddEditManager}
          style={{ width: "100%", margin: "0" }}
          form={managerForm}
        >
          <Row gutter={[20, 10]}>
            <Col span={12}>
              <Form.Item
                name="first_name"
                label={t("first_name")}
                rules={[
                  {
                    required: true,
                    message: t("validate_first_name_required"),
                    whitespace: true,
                  },
                  {
                    min: 3,
                    message: t("validate_first_name_min_characters"),
                  },
                ]}
                hasFeedback
              >
                <Input />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                name="last_name"
                label={t("last_name")}
                rules={[
                  {
                    required: true,
                    message: t("validate_last_name_required"),
                    whitespace: true,
                  },
                  {
                    min: 3,
                    message: t("validate_last_name_min_characters"),
                  },
                ]}
                hasFeedback
              >
                <Input autoComplete="off" />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                name="phone"
                label={t("phone_number") + " eg: +966 5xxxxxxx"}
                rules={[
                  {
                    required: true,
                    message: t("validate_phone_required"),
                  },
                  {
                    min: 12,
                    message: t("validate_phone_exact_limit"),
                  },
                  {
                    max: 12,
                    message: t("validate_phone_exact_limit"),
                  },
                ]}
                style={{ textAlign: "left" }}
                hasFeedback
              >
                <PhoneInput
                  localization={["sa"]}
                  masks={{ sa: "(...) ..-..-.." }}
                  placeholder="+966 (123) 45-67-89"
                  country={"sa"}
                  onlyCountries={["sa"]}
                  style={{
                    width: "100%",
                  }}
                />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                name="email"
                label={t("email")}
                rules={[
                  {
                    type: "email",
                    message: t("validate_email_invalid"),
                  },
                ]}
                hasFeedback
              >
                <Input />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                name="password"
                label={t("password")}
                rules={
                  validatePassword && [
                    {
                      required: true,
                      message: t("validate_password_required"),
                    },
                    {
                      min: 4,
                      message: t("validate_password_min_4_characters"),
                    },
                  ]
                }
                hasFeedback
              >
                <Input.Password
                  onChange={passwordValueChanged}
                  autoComplete="off"
                />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                name="password_confirmation"
                label={t("confirm_password")}
                dependencies={["password"]}
                hasFeedback
                rules={
                  validatePassword && [
                    {
                      required: true,
                      message: t("validate_password_confirm_not_matched"),
                    },
                    ({ getFieldValue }) => ({
                      validator(_, value) {
                        if (!value || getFieldValue("password") === value) {
                          return Promise.resolve();
                        }

                        return Promise.reject(
                          new Error(t("validate_password_confirm_not_matched"))
                        );
                      },
                    }),
                  ]
                }
              >
                <Input.Password autoComplete="off" />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item
                name="is_active"
                label={t("status")}
                rules={[
                  {
                    required: true,
                    message: t("validate_status_required"),
                  },
                ]}
              >
                <Radio.Group buttonStyle="solid" key="is-active">
                  <Radio.Button key="is-active-false" value={false}>
                    {t("inactive")}
                  </Radio.Button>
                  <Radio.Button key="is-active-true" value={true}>
                    {t("active")}
                  </Radio.Button>
                </Radio.Group>
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item name="id" style={{ display: "none" }}>
                <input type="hidden" />
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </Modal>
    </>
  );
};

export default Managers;
