import React, { useContext, useEffect, useState } from "react";
import { PageHeader, Card, Checkbox, Spin } from "antd";
import { HomeOutlined, GlobalOutlined } from "@ant-design/icons";
import { updateFeature, getMyProfile } from "../../../network/network";
import CustomBreadcrumb from "../../../components/CustomBreadcrumb";
import { useTranslation } from "react-i18next";
import { AppContext } from "../../../context/AppContextProvider";
import NotificationWithIcon from "../../../components/NotificationWithIcon";

const Features = () => {
  const { authUser, setAuthUser } = useContext(AppContext);
  const [loading, setLoading] = useState(false);
  const [dataLoading, setDataLoading] = useState(false);

  const { t } = useTranslation();

  useEffect(() => {
    // Set Loading True
    setLoading(true);
    getMyProfile()
      .then((response) => {
        setAuthUser(response.data.data.user);
      })
      .catch((error) => {})
      .finally(() => {
        // Set Loading False
        setLoading(false);
      });
  }, [dataLoading, setAuthUser]);

  const breadcrumbRoutes = [
    {
      path: "home",
      breadcrumbName: t("menu_home"),
      icon: <HomeOutlined />,
    },
    {
      path: "activity-type",
      breadcrumbName: t("menu_features"),
      icon: <GlobalOutlined />,
    },
  ];

  const handleUpdate = (value, id) => {
    // Set Loading True
    setLoading(true);
    const formData = new FormData();
    if (id) {
      formData.append("id", id);
      formData.append("status", value === true ? 1 : 0);
    }
    updateFeature(formData)
      .then((response) => {
        let data = response.data || response.response.data;
        if (data.success === true) {
          setDataLoading(!dataLoading);
          // Success
          NotificationWithIcon(
            "success",
            t("feature_updated"),
            t("feature_updated_successfully")
          );
        } else {
          // Error
          NotificationWithIcon(
            "error",
            t("something_went_wrong"),
            data?.message
          );
        }
      })
      .catch((error) => {})
      .finally(() => {
        // Set Loading False
        setLoading(false);
      });
  };

  return (
    <>
      <CustomBreadcrumb routes={breadcrumbRoutes} />
      <PageHeader
        className="site-page-header"
        title={t("menu_features")}
        ghost={false}
      />
      <Spin spinning={loading}>
        <Card>
          {authUser.features.map((feature) => (
            <div style={{ marginBottom: "10px" }}>
              <Checkbox
                onChange={(e) => {
                  handleUpdate(e.target.checked, feature.id);
                }}
                checked={feature.status}
              >
                {feature.title}
              </Checkbox>
            </div>
          ))}
        </Card>
      </Spin>
    </>
  );
};

export default Features;
