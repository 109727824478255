import Environment from "./baseUrl";
import axios from "axios";

const getHeaders = async (token) => {
  if (token) {
    return {
      Authorization: "Bearer " + token,
      "Content-Type": "application/json",
      Accept: "application/json",
      "Access-Control-Allow-Origin": "*",
    };
  } else {
    return {
      "Content-Type": "application/json",
      Accept: "application/json",
      // 'Authorization':  APP_NORMAL,
    };
  }
};

const getProfilePictureUploadHeaders = (token) => {
  if (token) {
    return {
      Authorization: "Bearer " + token,
      "Content-Type": "multipart/form-data",
      Accept: "application/json",
      "Access-Control-Allow-Origin": "*",
    };
  } else {
    return {
      Authorization: Environment.BasicToken,
      "Content-Type": "application/json",
      Accept: "application/json",
      "Access-Control-Allow-Origin": "*",
    };
  }
};

var profilePictureOptions = {
  method: null,
  data: null,
  headers: null,
};

var authOptions = {
  method: null,
  data: null,
  headers: getHeaders(),
};

axios.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    if (error.response.status === 401) {
      //When Unauthorized
      localStorage.removeItem("last_visited_page");
      localStorage.removeItem("access_token");
      window.location = "/login";
    }
    return error;
  }
);

export const doPost = async (url, data, token) => {
  authOptions.method = "POST";
  authOptions.data = data;
  authOptions.headers = await getHeaders(token);
  return axios(Environment.API_BASE_URL + url, authOptions);
};
export const doPatch = async (url, data, token) => {
  authOptions.method = "Patch";
  authOptions.data = data;
  authOptions.headers = await getHeaders(token);
  return axios(Environment.API_BASE_URL + url, authOptions);
};


export const doGet = async (url, token, data) => {
  authOptions.headers = await getHeaders(token);
  authOptions.method = "GET";
  authOptions.data = null;
  if (data) {
    var params = Object.keys(data)
      .map((key) => key + "=" + data[key])
      .join("&");

    if (params) {
      url = url + "?" + params;
    }
  }
  return axios(Environment.API_BASE_URL + url, authOptions);
};

export const doDelete = async (url, data, token) => {
  authOptions.method = "DELETE";
  authOptions.data = data;
  authOptions.headers = await getHeaders(token);
  return axios(Environment.API_BASE_URL + url, authOptions);
};

export const doPostImageUpload = (url, data, token) => {
  profilePictureOptions.method = "POST";
  profilePictureOptions.data = data;
  profilePictureOptions.headers = getProfilePictureUploadHeaders(token);
  return axios(Environment.API_BASE_URL + url, profilePictureOptions);
};
