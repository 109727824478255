import React from "react";
import { Tabs } from "antd";
import CompanyActivities from "../CompanyGeneralDetails/CompanyActivities";
import CompanyNotes from "../CompanyGeneralDetails/CompanyNotes";
import CompanyLogs from "../CompanyGeneralDetails/CompanyLogs";
import CompanyQuestionnaire from "../CompanyGeneralDetails/CompanyQuestionnaire";

const CompanyGeneralInfo = ({ company, reloadCallback }) => {
  return (
    <>
      <Tabs defaultActiveKey="tab-logs">
        <Tabs.TabPane tab="Logs" key="tab-logs">
          <CompanyLogs />
        </Tabs.TabPane>
        <Tabs.TabPane tab="Activities" key="tab-activities">
          <CompanyActivities />
        </Tabs.TabPane>
        <Tabs.TabPane tab="Questionnaire" key="tab-questionnaire">
          <CompanyQuestionnaire />
        </Tabs.TabPane>
        <Tabs.TabPane tab="Notes" key="tab-notes">
          <CompanyNotes />
        </Tabs.TabPane>
      </Tabs>
    </>
  );
};

export default CompanyGeneralInfo;
