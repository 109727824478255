import React, { useEffect, useState } from "react";
import { Button, Form, Input, Spin, Row, Col, Select } from "antd";
import { useTranslation } from "react-i18next";
import NotificationWithIcon from "../../../components/NotificationWithIcon";
import { useParams } from "react-router-dom";
import {
  postQuestionnaireData,
  getLearningSources,
  getQuestionnaireData,
} from "../../../network/network";
import { platformType } from "../../../configs/constants";

const CompanyQuestionnaire = () => {
  const [QuestionnaireData, setQuestionnaireData] = useState([]);
  const [learningSources, setLearningSources] = useState([]);
  const [loading, setLoading] = useState(false);
  const [otherLearningSourceID, setOtherLearningSourceID] = useState(null);
  const [questionnaireForm] = Form.useForm();
  const { t } = useTranslation();
  let { companyId } = useParams();

  useEffect(() => {
    setLoading(true);
    getLearningSources()
      .then((response) => {
        setLearningSources(response.data.data.learning_sources);
      })
      .finally(() => {
        setLoading(false);
      });
  }, []);

  useEffect(() => {
    setLoading(true);
    getQuestionnaireData(companyId)
      .then((response) => {
        setQuestionnaireData(response.data.data);
      })
      .finally(() => {
        setLoading(false);
      });
  }, [companyId]);

  // Set Form Fields
  useEffect(() => {
    QuestionnaireData?.learning_source?.id === 11
      ? setOtherLearningSourceID(11)
      : setOtherLearningSourceID(null);
    questionnaireForm.setFieldsValue({
      learning_source_id: QuestionnaireData?.learning_source?.id,
      other_learning_source: QuestionnaireData?.other_learning_source,
      preferred_platform: QuestionnaireData?.preferred_platform,
      new_or_existing: QuestionnaireData?.new_or_existing,
    });
  }, [QuestionnaireData, questionnaireForm]);

  const handleAddEdit = () => {
    questionnaireForm
      .validateFields()
      .then((values) => {
        // Set Loading True
        setLoading(true);
        // Sending Request to API
        postQuestionnaireData(companyId, values)
          .then((response) => {
            let data = response.data || response.response.data;
            if (data.success === true) {
              // Success
              NotificationWithIcon(
                "success",
                response.data.message
                // t("note_added_successfully")
              );
            } else {
              let errors = data?.data?.errors;
              if (errors && Object.keys(errors).length) {
                // Error
                NotificationWithIcon(
                  "error",
                  t("something_went_wrong"),
                  errors[Object.keys(errors)[0]]
                );
              }
            }
          })
          .catch((info) => {
            console.log("API Failed:", info.response);
            // Error
            NotificationWithIcon(
              "error",
              t("something_went_wrong"),
              info.response.data.data.errors.name
            );
          })
          .finally(() => {
            // Set Loading False
            setLoading(false);
          });
      })
      .catch((info) => {
        console.log("Validate Failed:", info);
      });
  };

  return (
    <>
      <Spin size="large" spinning={loading}>
        {/* add or edit question form */}
        <Form
          name="questionnaire-form"
          layout="vertical"
          onFinish={handleAddEdit}
          style={{ width: "100%", margin: "20px auto 0" }}
          form={questionnaireForm}
        >
          <Row gutter={[20, 20]}>
            <Col xs={24} sm={24} md={12} lg={12} xl={8} xxl={8}>
              <Form.Item
                name="learning_source_id"
                label={t("how_did_you_learn_about_AnyPOS")}
                rules={[
                  {
                    required: true,
                    message: t("validate_learning_source_required"),
                  },
                ]}
              >
                <Select
                  onChange={(value) => {
                    value === 11
                      ? setOtherLearningSourceID(value)
                      : setOtherLearningSourceID(null);
                  }}
                  showSearch
                  allowClear
                  options={learningSources.map((source) => {
                    return {
                      label: source.source,
                      value: source.id,
                    };
                  })}
                  filterOption={(input, option) =>
                    (option?.label ?? "")
                      .toLowerCase()
                      .includes(input.toLowerCase())
                  }
                />
              </Form.Item>
              {otherLearningSourceID && (
                <Form.Item
                  name="other_learning_source"
                  label={t("other_platform")}
                >
                  <Input />
                </Form.Item>
              )}
            </Col>
            <Col xs={24} sm={24} md={12} lg={12} xl={8} xxl={8}>
              <Form.Item
                name="preferred_platform"
                label={t("your_preferred_platform")}
                rules={[
                  {
                    required: true,
                    message: t("validate_preferred_platform_required"),
                  },
                ]}
              >
                <Select
                  showSearch
                  allowClear
                  options={platformType.map((type) => {
                    return {
                      label: type.text,
                      value: type.value,
                    };
                  })}
                  filterOption={(input, option) =>
                    (option?.label ?? "")
                      .toLowerCase()
                      .includes(input.toLowerCase())
                  }
                />
              </Form.Item>
            </Col>
            <Col xs={24} sm={24} md={12} lg={12} xl={8} xxl={8}>
              <Form.Item
                name="new_or_existing"
                label={t("is_your_business_newly_established_or_existing")}
                rules={[
                  {
                    required: true,
                    message: t("validate_new_or_existing_required"),
                  },
                ]}
              >
                <Input />
              </Form.Item>
            </Col>
            <Col
              xs={24}
              sm={24}
              md={24}
              lg={24}
              xl={24}
              xxl={24}
              style={{ textAlign: "center" }}
            >
              <Form.Item>
                <Button type="primary" onClick={handleAddEdit}>
                  {t("save")}
                </Button>
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </Spin>
    </>
  );
};

export default CompanyQuestionnaire;
