import React from "react";
import {
  MailOutlined,
  UserSwitchOutlined,
  CheckCircleOutlined,
  PhoneOutlined,
  ClockCircleOutlined,
  CalendarOutlined,
  CalculatorOutlined,
  FormOutlined,
  ReadOutlined,
  MobileOutlined,
  CreditCardOutlined,
  BellOutlined,
  MessageOutlined,
  CameraOutlined,
  FolderOpenOutlined,
  StopOutlined,
} from "@ant-design/icons";
import { Tooltip } from "antd";

const FindActivityTypeIcon = ({ name, title }) => {
  const renderSwitch = (name) => {
    switch (name) {
      case "MailOutlined":
        return <MailOutlined />;
      case "FormOutlined":
        return <FormOutlined />;
      case "PhoneOutlined":
        return <PhoneOutlined />;
      case "CalendarOutlined":
        return <CalendarOutlined />;
      case "CalculatorOutlined":
        return <CalculatorOutlined />;
      case "BellOutlined":
        return <BellOutlined />;
      case "MessageOutlined":
        return <MessageOutlined />;
      case "CheckCircleOutlined":
        return <CheckCircleOutlined />;
      case "ReadOutlined":
        return <ReadOutlined />;
      case "CameraOutlined":
        return <CameraOutlined />;
      case "FolderOpenOutlined":
        return <FolderOpenOutlined />;
      case "MobileOutlined":
        return <MobileOutlined />;
      case "CreditCardOutlined":
        return <CreditCardOutlined />;
      case "UserSwitchOutlined":
        return <UserSwitchOutlined />;
      case "ClockCircleOutlined":
        return <ClockCircleOutlined />;
      case "StopOutlined":
        return <StopOutlined />;
      default:
        return "";
    }
  };

  return (
    <span>
      <Tooltip title={title}>{renderSwitch(name)}</Tooltip>
    </span>
  );
};

export default FindActivityTypeIcon;
